import React from "react";
import InfoCarousel from "./infocarousel";

const ExtraInfo = () => {
  return (
    <div className="w-full sm:px-[3vh] py-[9vh] sm:py-[13vh] flex sm:flex-row flex-col-reverse gap-y-4">

      <InfoCarousel></InfoCarousel>

      <div className="w-auto sm:w-[60%] mx-8 sm:ml-[3vw] lg:ml-[2vw] text-[#5B2980]">

        <h2 className="text-left font-FireSans leading-tight font-[700] text-[5vw] sm:text-[3vw]">
          Why PUBBS Transit v2.0?
        </h2>

        <ol className="list-disc font-medium leading-tight space-y-[4vh] text-[3vw] sm:text-[2vw] lg:text-[1.5vw] mt-[3vh] lg:mt-[10vh] text-justify sm:font-[400] lg:font-[300] ml-[3vw] lg:ml-[2vw] sm:mr-[8vw]">

          <li>
            Software designed to reduce the operation cost of all buses for each
            trip for each route from each terminal while improving passenger
            comfort.
          </li>

          <li>
            System is flexible to work on various levels of ITS infrastructure
            (On board driver console, CCTV cameras within buses and bus stops
            and APC modules within buses).
          </li>

          <li>
            Software and training will be provided free of cost.
            Maintenance/hosting/subscription cost is insignificant compared to
            savings achieved in bus operations.
          </li>

        </ol>

      </div>

    </div>
  );
};

export default ExtraInfo;
