import React from "react";
import "./Pages.css";
import "./tree.css";
import desktop from "../assets/resources/desktop.png";
import desktop2 from "../assets/resources/desktop2.png";
import download from "../assets/resources/download.png";
import { Link } from "react-router-dom";

const Resources = () => {
  return (
    <div className="resources-main">
      <div className="mt-[3%] text-center sm:text-start sm:ml-[4rem] font-bold text-[2rem] text-[#45177D] sm:text-[2.2rem] md:text-[2.8rem]">PUBBS Transit v1.O</div>
      <div className="resources-sharing-div gap-y-8 my-12 sm:mb-8 sm:mt-0">
        <div className="resources-left">
          <ul className="tree">
            <div className="text-[1.5rem] md:text-[2rem] font-medium text-[#45177D] underline">Bus Service Simulation Software</div>
            <li className="last">
              Single route frequency setting and timetable development
            </li>
            <li className="last">
              Single route full day bus service optimization using
              micro-simulation
            </li>
            <li class="last">Single route scheduling with dedicated fleet</li>
            <li className="last">
              Excel based platform utilizing VBA platform
            </li>
          </ul>
        </div>
        <div className="resources-right">
          <img src={desktop} alt="" />
          <div className="buttons">
            <Link target="_blank" to={'https://docs.google.com/spreadsheets/d/1tmaoXl261q55Ls7dUzQ5KK3xIMqySwaq/edit?usp=sharing&ouid=117823592197393456772&rtpof=true&sd=true'} className="text-white bg-gradient-to-r from-[#8C025D] to-[#45177D] font-[600] border-[#8C025D] py-[1.8vw] px-[3vw] sm:py-[1vw] sm:px-[2vw] rounded-[5vw] border-[0.2vw] text-[2.5vw] sm:text-[1.5vw] lg:text-[1vw] but-div  hover:bg-gradient-to-r hover:from-[#45177D] hover:to-[#8C025D]">
              software <img src={download} alt="" />
            </Link>
            <Link target="_blank" to={'https://drive.google.com/file/d/11b3by8Bkxjd5UmTteI2GL1gsRLCUVOdd/view?usp=sharing'} className="text-[#8C025D] font-[600] border-[#8C025D] py-[1.8vw] px-[3vw] sm:py-[1vw] sm:px-[2vw] rounded-[5vw] border-[0.2vw] text-[2.5vw] sm:text-[1.5vw] lg:text-[1vw] hover:bg-gradient-to-r from-[#8C025D] to-[#45177D]  hover:text-white">
              MANUAL
            </Link>
          </div>
        </div>
      </div>
      <div className="mt-[3%] text-center sm:text-start sm:ml-[4rem] font-bold text-[2rem] text-[#45177D] sm:text-[2.2rem] md:text-[2.8rem]">PUBBS Transit v2.O</div>
      <div className="resources-sharing-div gap-y-14 my-12 sm:mb-8 sm:mt-0">
        
        <div className="resources-left">
          <div className="text-[1.5rem] md:text-[2rem] font-medium text-[#45177D] underline">
            Bus Service Planning and Operation Software
          </div>
          <ul className="ul">
            <li className="li">Network creation and visualization</li>
            <li className="li">
              Single depot multiple route frequency setting and timetable
              development{" "}
            </li>
            <li className="li">
              Single and Multiple route sequential and simultaneous scheduling
            </li>
            <li className="li">Crew rostering</li>
            <li className="li"> Dispatch, Control and Surveillance System </li>
            <li className="li"> Driver console</li>
          </ul>
        </div>

        <div className="resources-right">
          <img className="down-desktop" src={desktop2} alt="" />
          <div className="buttons">
            <Link to="/form" className="text-white bg-gradient-to-r from-[#8C025D] to-[#45177D] font-[600] border-[#8C025D] py-[1.8vw] px-[3vw] sm:py-[1vw] sm:px-[2vw] rounded-[5vw] border-[0.2vw] text-[2.5vw] sm:text-[1.5vw] lg:text-[1vw] hover:bg-gradient-to-r hover:from-[#45177D] hover:to-[#8C025D]">
              <Link to="/form">REQUEST FOR DEMO</Link>
            </Link>
            <Link to={'https://drive.google.com/file/d/1ifsOg94k-f8quk2OJDAkDHIHQbhh8ADU/view?usp=sharing'} className="hover:bg-gradient-to-r from-[#8C025D] to-[#45177D]  hover:text-white text-[#8C025D] font-[600] border-[#8C025D] py-[1.8vw] px-[3vw] sm:py-[1vw] sm:px-[2vw] rounded-[5vw] border-[0.2vw] text-[2.5vw] sm:text-[1.5vw] lg:text-[1vw]">
              MANUAL
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Resources;
