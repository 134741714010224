import React from "react";
import card1 from "../../assets/Network/card1.png";
import circle1 from "../../assets/Network/circle1.png";

const ImnagTextCard = () => {
  return (
    <div className="flex md:flex-row flex-col overflow-x-hidden">

      <div className="p-[3vw] relative">
        <img
          className="absolute w-[150px] md:w-auto h-[150px] md:h-[30vh] left-[11.5rem] md:left-auto z-[2] md:right-[0vw] lg:right-[1vw] top-[0vw] lg:top-[2vw]"
          src={circle1}
          alt=""
        />

        <img className="w-[311px] h-[200px] md:h-[50vh] md:w-auto" src={card1} alt="" />

      </div>

      <div className="p-[3vw] w-[95%] md:w-1/2 h-[200px] md:h-[50vh] bg-[#68507a2b] md:bg-transparent relative left-8 bottom-16 md:left-0 md:bottom-0 z-[-2] flex place-items-center content-center">

        <ul className="list-disc-none sm:list-disc text-[0.8rem] md:text-[1rem] lg:text-[1.5vw] space-y-[2vh] text-[#45177D]">

          <li>
            Identify bus stops, intersections and intermediate points on open
            source map/satellite image.
          </li>

          <li>
            Seamless integration of passenger demand at each bus stop at
            different times of the day.
          </li>

        </ul>

      </div>

    </div>
  );
};

export default ImnagTextCard;