import React from 'react'
import comingSoon from '../assets/coming soon-01 1.png'

const Electric = () => {
  return (
    <div className='h-[50vh] md:h-[90vh] w-full'>
        <div className='h-full w-full flex flex-col items-center justify-center'>
            <h1 className='absolute top-40 font-semibold text-[#45177D] md:text-5xl text-3xl'>Coming Soon</h1>
            <img src={comingSoon} alt="" />
        </div>
    </div>
  )
}

export default Electric