import React from "react";
import TextAndCard from "../components/universal/TextAndCard";


let images = [
  { card: "assets/control/card1.png", circle: "assets/control/circle1.png" },
  { card: "assets/control/card2.png", circle: "assets/control/circle2.png" },
];

let texts = [
  [
    "Estimated time of arrival of buses at bus stops",
    "Hybrid technique combining bus dwell time predictions with link running time",
    "Flexibility in model calibration for realistic ETAs",
  ],
  [
    "Passenger load, Crowding level, Congestion",
    "CCTV feeds from buses and bus stop (options to integrate)",
    "Panic response system",
    "Real-time bus trajectory monitoring for detection of bus bunching",
  ],
  [],
];
const Control = () => {
  return (
    <div className="w-auto font-FiraSans leading-tight">
      <div className="w-auto h-[60vh] sm:h-[80vh] lg:h-[100vh] flex">
        <div className="md:h-[50vh] h-[60vw] relative w-[85%] bg-[#E32F6B14]">
          <h2 className="text-left pl-[5vw] pt-[5vh] md:pt-[10vh] font-FireSans leading-tight text-[#45177D] font-[700] text-[4.7vw] md:text-[3.7vw]">
            Dispatch, Control and Surveillance <br /> System
          </h2>
          <div className="w-[40vw] text-[0.7rem] sm:text-[1rem] text-justify text-[#45177D] ml-[8vw] mt-[2vw] leading-normal font-light">
            <ul className="list-disc">
              <li>Operator console and Surveillance System</li>
              <li>Dynamic bus dispatch and control system</li>
            </ul>
          </div>
        </div>

        <div className="w-[270px] sm:w-[500px] lg:w-[600px] absolute right-[4vw] bottom-[42vh] sm:right-[5vw] lg:right-[3vw] sm:bottom-[17vh] lg:bottom-[3vh] shadow-lg rounded-xl opacity-80">
          <div className="w-full rounded-xl bg-white aspect-[806/453]">
            <iframe
              className="w-full sm:h-fit h-[176px] aspect-[806/453] rounded-lg"
              src='https://www.youtube.com/embed/07ccphHZto4?rel=0'
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
        </div>
        
      </div>
      <div className="w-auto bg-[#894472] text-white">
        <h2 className="text-[1.2rem] sm:text-[1.5rem] md:text-[1.8rem] underline pt-[4vw] pl-[4vw] font-bold tracking-wider">
          Dispatch, Control and Surveillance System
        </h2>
        <ul className="text-[1.2rem] sm:text-[1.4rem] md:text-[1.6rem] ml-[4vw] mt-[4vw] sm:inline-block hidden">
          <li>Route level monitoring</li>
        </ul>
        <TextAndCard
          texts={texts}
          images={images}
          index={1}
          light={true}
        ></TextAndCard>
        <TextAndCard
          texts={texts}
          images={images}
          index={0}
          light={true}
        ></TextAndCard>
      </div>
      <div className="bg-[#4B3369] text-white">
        <div className="grid w-auto mt-[5vh] sm:grid-cols-2 ">
          <div className="w-auto mr-[1vw]">
            <h2 className="text-[1.2rem] sm:text-[1.5rem] md:text-[1.8rem] underline pt-[4vw] pl-[4vw] font-bold tracking-wider">
              Dynamic bus dispatch and control system
            </h2>
            <ul className="list-disc space-y-[2vw] font-FiraSans text-[0.8rem] sm:text-[1.5vw] ml-[10vw] mt-[4vw]">
              <li>
                Hybrid holding strategy combining Schedule based, Headway based
                and Optimisation based approaches
              </li>
              <li>
                Stabilizing the disrupted bus system with the introduction of
                delays for all affected buses and bringing the system back to
                the target schedule
              </li>
              <li>Driver information system</li>
            </ul>
          </div>
          <div className="w-auto hidden md:block h-full mt-[8vw]">
            <p className="px-[10vw] text-justify  md:text-[1.1vw]">
              The public bus transit system operates in general traffic and is
              vulnerable to uncertainties in travel time and passenger demand. A
              small delay can induce larger delays in a high frequency transit
              over time and it will lead the buses to move in pairs or platoons
              instead of being evenly spaced. This is generally perceived as a
              system with low reliability. The dynamic control system reacts to
              these disruptions in real time. It can control the adverse effect
              of bus bunching, overcrowding and schedule sliding and can improve
              the reliability of the service.
            </p>
          </div>
        </div>
        <div className="flex bg-[#4B3369] space-x-[5vw] pl-[10vw] my-[5vw] pb-[10vw] md:pb-[5vw]">
          <div className="w-[42%] md:w-[22%]">
            <img
              className="h-[80%]"
              src="/assets/control/diagram1.png"
              alt=""
            />
            <p className="mt-[2vh] text-center md:text-[0.9vw] text-[2.5vw]">
              Space time diagram for buses with no delay
            </p>
          </div>
          <div className="w-[42%] md:w-[22%]">
            <img
              className="h-[80%]"
              src="/assets/control/diagram2.png"
              alt=""
            />
            <p className="mt-[2vh] md:text-[0.9vw] text-[2.5vw] text-center">
              Time space diagram overlapping trajectories shows bus bunching
              event
            </p>
          </div>
          <div className="pt-[2vh] pl-[2vw] hidden md:block">
            <ul className="list-disc space-y-[2vh]">
              <li>Flexible control points</li>
              <li>Flexible slack times</li>
              <li>Flexible cost function</li>
              <li>Genetic algorithm-based optimization</li>
            </ul>
          </div>
        </div>
        <div className="pt-[1vh] pl-[12vw] pb-[2vh] md:hidden ">
          <ul className="list-disc space-y-[0.2vh]">
            <li>Flexible control points</li>
            <li>Flexible slack times</li>
            <li>Flexible cost function</li>
            <li>Genetic algorithm-based optimization</li>
          </ul>
        </div>
      </div>
      <div className="w-auto mb-[2vh] md:hidden bg-[#4B3369] text-white py-[5vh] h-full mt-[8vw]">
        <p className="px-[6vw] text-justify text-[0.9rem] sm:text-[3.2vw]">
          The public bus transit system operates in general traffic and is
          vulnerable to uncertainties in travel time and passenger demand. A
          small delay can induce larger delays in a high frequency transit over
          time and it will lead the buses to move in pairs or platoons instead
          of being evenly spaced. This is generally perceived as a system with
          low reliability. The dynamic control system reacts to these
          disruptions in real time. It can control the adverse effect of bus
          bunching, overcrowding and schedule sliding and can improve the
          reliability of the service.
        </p>
      </div>
    </div>
  );
};

export default Control;
