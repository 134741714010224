import React from "react";
import download from '../assets/Frame 1000002181.png'
import { Link } from "react-router-dom";

const ResearchProject = () => {
  return (
    <div>
      <div className="md:m-12 m-4">
        <h1 className="text-[#8C025D] font-black text-3xl">PUBBS Transit v1.O</h1>

        <div className="flex flex-col gap-y-8 my-8">

          <div>

            <p className="text-[#45177D] font-normal text-lg">Service Level Optimization Between Public Bus and Paratransit Services Along a Transport Corridor, Ministry of Housing and Urban Affairs (2015-19)</p>

            <div className="text-[#45177D] font-light text-sm p-4">

              <ul style={{ listStyle: 'inside' }}>

                <li className="md:py-0 py-2">
                  Determine the passenger travel demand including a modal split model between public transit and para-transit services.
                </li>

                <li className="md:py-0 py-2">
                  Determine feasible range of service delivery levels for transit services. This essentially has three components: a. To determine the Level of Service ranges for bus transit and para-transit services based on users’ perception in the Indian context, b. To determine the expected service levels of users across various user groups, and c. To determine service levels that provides maximum marginal utility to the service providers.
                </li>

                <li className="md:py-0 py-2">
                  Determine the optimal level of service for public bus transport and para-transit (in terms of fleet size, vehicle capacity, headway, etc.) during peak and off-peak hours.
                </li>

                <li className="md:py-0 py-2">
                  Development of a framework for regulation and implementation.
                </li>
              </ul>

            </div>

          </div>

          <div>

            <p className="text-[#45177D] font-normal text-lg underline">Masters and PhD Research</p>

            <div className="text-[#45177D] font-light text-sm p-4">

              <ul className="" style={{ listStyle: 'inside' }}>

                <li className="">
                  A simulation based optimization for frequency setting and timetable development.
                </li>

                <li className="md:py-0 py-2">
                  for a single bus route considering individual passenger journey experience, PhD thesis, 2020 by Krishanu Santra.
                </li>

                <li className="md:py-0 py-2">
                  A Framework to Improve Bus Transit Service Quality Considering the Asymmetric Relationship between User Satisfaction and Service Level, PhD thesis, 2020 by Tiyali Bose.
                </li>

                <li className="md:py-0 py-2">
                  Service quality assessment for paratransit along a major transit corridor: A case of Kolkata, MCP thesis project, 2018, By Deepa Sharma.
                </li>

                <li className="md:py-0 py-2">
                  Integration of Bus transit and para-transit along a network corridor in Kolkata, MCP thesis project, 2019, By Somnath Bhui.
                </li>

              </ul>

            </div>

          </div>

        </div>

        <div className="my-8">

          <h1 className="text-[#45177D] font-normal text-xl">Handbooks</h1>

          <div className="text-[#45177D] font-light text-sm p-4">

            <ul>

              <li className="flex flex-col">

                1. Handbook on Feasible Service Delivery Level Ranges for Bus Transit: Part 1: User perceptive, Handbook on Feasible Service

                <span className="cursor-pointer">
                  <Link target="_blank" to={'https://drive.google.com/file/d/1GCOvoWRAJz_p_5Csy2nuEFcxjSURjl56/view?usp=sharing'}>
                    <img className="h-[2rem] relative bottom-[0.5rem] left-[0.7rem] -z-10" src={download} alt="" />
                  </Link>
                </span>

              </li>

              <li className="flex flex-col">

                2. Delivery Level Ranges for Bus Transit: Part 2: Operator perceptive

                <span className="cursor-pointer">
                  <Link target="_blank" to={'https://drive.google.com/file/d/1Rm56pw79h31siIEYpWUJBw3FXfmBesWw/view?usp=sharing'}>
                  <img className="h-[2rem] relative bottom-[0.5rem] left-[0.7rem] -z-10" src={download} alt="" />
                  </Link>
                </span>

              </li>

              <li className="flex flex-col">

                3. Handbook on Para-transit Service Design in Indian Context: Part 1: Operation and Regulation

                <span className="cursor-pointer">
                  <Link target="_blank" to={'https://drive.google.com/file/d/1BRX4dvM2z1gSLU98Z3pjsM7LjbG6YIaa/view?usp=sharing'}>
                  <img className="h-[2rem] relative bottom-[0.5rem] left-[0.7rem] -z-10" src={download} alt="" />
                  </Link>
                  </span>

              </li>

              <li className="flex flex-col">

                4. Handbook on Para-transit Service Design in Indian Context: Part 2: Integration

                <span className="cursor-pointer">
                  <Link target="_blank" to={'https://drive.google.com/file/d/17LZD2kO5JSBQdSHjmddP4X1y6kI2aPwX/view?usp=sharing'}>
                  <img className="h-[2rem] relative bottom-[0.5rem] left-[0.7rem] -z-10" src={download} alt="" />
                  </Link>
                  </span>

              </li>

              <li className="flex flex-col">

                5. Service Level Optimisation between Public Bus and Para-transit Services along a Transport Corridor:

                <span className="cursor-pointer">
                  <Link target="_blank" to={'https://drive.google.com/file/d/11b3by8Bkxjd5UmTteI2GL1gsRLCUVOdd/view?usp=sharing'}>
                    <img className="h-[2rem] relative bottom-[0.5rem] left-[0.7rem] -z-10" src={download} alt="" />
                  </Link>
                </span>

              </li>

            </ul>

          </div>

          <div className="flex flex-col gap-y-2 items-center justify-center w-fit">

            <h1 className="text-[#45177D] font-normal text-lg">Project report (PUBBS Transit v1.0)</h1>

            <Link target="_blank" to={'https://drive.google.com/file/d/1MqU78ZXE8m0Vc7F0LsPPQoESrlaA2QfJ/view?usp=sharing'} className="text-white bg-gradient-to-r w-fit px-6 from-[#8C025D] to-[#45177D] font-[600] border-[#8C025D] py-2 text-sm rounded-[1.5rem] border-[0.2vw] hover:bg-gradient-to-r hover:from-[#45177D] hover:to-[#8C025D]">
              Download Report
            </Link>

          </div>

        </div>

        <div className="my-24">

          <h1 className="text-[#8C025D] font-black text-3xl">PUBBS Transit v2.O</h1>

          <div>

            <p className="text-[#45177D] font-normal text-base md:text-lg mt-6 mb-4">An Intelligent Urban Bus Transit Dispatch, Control and Surveillance System using Artificial Intelligence and User Perception. Ministry of Housing and Urban Affairs (2019-2023)</p>

            <div>

              <p className="text-[#45177D] font-light text-base md:text-lg">
                This project aims to develop a fully automated bus dispatch, control and surveillance system using artificial intelligence by integrating predicted passenger demand, passenger expectations, operator’s resources and historical data collected using ETM and AVL systems and through primary surveys.
              </p>

              <ul className="text-[#45177D] font-light text-sm my-4">

                <li className="md:py-0 py-2">
                  i. Collection and storage of GPS location data from bus tracking systems, electronic ticketing data of different routes and other relevant data.
                </li>

                <li className="md:py-0 py-2">
                  ii. Development of algorithms and software for predicting:
                </li>

                <li className="md:py-0 py-2">
                  a. Future transit passenger travel demand for bus routes (Passenger arrival rate at bus stops, Passenger O-D at each bus stop)
                </li>

                <li className="md:py-0 py-2">
                  i. Long term transit passenger travel demand
                </li>

                <li className="md:py-0 py-2">
                  ii. Short term transit passenger travel demand variation
                </li>

                <li className="md:py-0 py-2">
                  b. Expected bus arrival times at bus stops at different times of the day (Using historical data, real time data on bus location, expected passenger boarding alighting count at bus stops and other information).
                </li>

                <li className="md:py-0 py-2">
                  iii. Development of models for determining route wise service schedule (frequency and stop wise timetable), bus schedules and crew requirement during service hours for bus operations
                </li>

                <li className="md:py-0 py-2">
                  iv. Development of algorithms and service strategies for automated bus dispatch, control and surveillance system considering disruptions in normal service and other exogenous factors
                </li>

              </ul>
            </div>

          </div>

          <div>

            <div>

              <p className="text-[#45177D] font-light text-lg underline">
              Masters and PhD Research conducted under this project
              </p>

              <ul className="text-[#45177D] font-light text-sm my-4">

                <li className="md:py-0 py-2">
                1. Electric bus planning and scheduling operation along a single transit corridor in Indian Context, MCP thesis project, 2023 By Reema Rani Sahoo.
                </li>

                <li className="md:py-0 py-2">
                2. A framework for determining charging infrastructure at E bus depot and terminals: A case of Delhi, MCP thesis project, 2023 By Shikha Gupta.
                </li>

                <li className="md:py-0 py-2">
                3. Intelligent Urban Bus Transit Dispatch, Control & Surveillance System”: Application at PMPML, Pune, 2023 By Pankaj Shivaji Pathare.
                </li>

                <li className="md:py-0 py-2">
                4. A Daily Activity-Travel Simulator for India Incorporating Multiday and Spatio-Temporal Interdependencies, PhD thesis, 2023 by Suchismita Nayak.
                </li>

                <li className="md:py-0 py-2">
                5. Dynamic dispatch and control strategies for bus transit operations in an urban corridor in Indian Context: a case of Kolkata public transit system, 2022 By Geethanjali J.
                </li>

                <li className="md:py-0 py-2">
                6. Determining the bus arrival information using automatic vehicle location system data: a case of Kolkata public transit system, MCP thesis project, 2021, By Pranav Vasantrao Kadoo.
                </li>

                <li className="md:py-0 py-2">
                7. Designing bus transit using intelligent transport system for Greater Raipur region, MCP thesis project, 2021, By Ashish Jaiswal
                </li>

              </ul>

            </div>

          </div>

          <div className="flex flex-col gap-y-2 items-center justify-center w-fit mt-12">

            <h1 className="text-[#45177D] font-bold text-lg">Project report (PUBBS Transit v2.0)</h1>

            <button className="text-white bg-gradient-to-r w-fit px-6 from-[#8C025D] to-[#45177D] font-[600] border-[#8C025D] py-2 text-sm rounded-[1.5rem] border-[0.2vw] hover:bg-gradient-to-r hover:from-[#45177D] hover:to-[#8C025D]">
              Download Report
            </button>

          </div>

        </div>

      </div>
    </div>
  );
};

export default ResearchProject;
