import React, { useState } from "react";
import InfoCard from "./infocard";
import leftarrow from "../../assets/leftarrow.svg";
import rightarrow from "../../assets/rightarrow.svg";

const InfoCarousel = () => {
  const [carouselIndex, setCarouselIndex] = useState(0);

  const handleLeftArrowClick = () => {
    let newIndex = 0;
    if (carouselIndex === 0) {
      newIndex = 2;
    } else {
      newIndex = carouselIndex - 1;
    }

    setCarouselIndex(newIndex);
  };

  const handleRightArrowClick = () => {
    let newIndex = (carouselIndex + 1) % 6;
    setCarouselIndex(newIndex);
  };

  return (
    <div className="max-h-[60vh] sm:h-auto w-full sm:w-[40%] lg:w-[33.9vw] sm:ml-[3vw] lg:ml-[8vw] bg-[#ACA5B1] sm:bg-transparent pl-[16px] pr-[21px] sm:px-[4.5vw] relative aspect-[651/583]">
      <img
        onClick={handleLeftArrowClick}
        className="absolute top-[8rem] sm:top-[20vh] lg:top-[25vh] w-[10vw] sm:w-[7vw] lg:w-[4vw] left-0 sm:left-[-20px] lg:left-0"
        src={leftarrow}
        alt=""
      />

      <img
        onClick={handleRightArrowClick}
        className="absolute right-[0px] sm:right-[-25px] xl:right-[-25px] lg:right-0 z-20 top-[8rem] sm:top-[20vh] lg:top-[25vh] w-[10vw] sm:w-[7vw] lg:w-[4vw]"
        src={rightarrow}
        alt=""
      />
      <InfoCard index={carouselIndex}></InfoCard>
    </div>
  );
};

export default InfoCarousel;
