import React from "react";

const TextAndCard = (props) => {
  const listItems = props.texts[props.index].map((item, index) => (
    <li key={index}>{item}</li>
  ));

  const part1 = (
    <div className="p-[5vw] relative">
      <img
        className="absolute w-[15vw] right-[1vw] top-[1vw]"
        src={props.images[props.index].circle}
        alt=""
      />
      <img className="h-[35vh]" src={props.images[props.index].card} alt="" />
    </div>
  );

  const part2 = (
    <div className="p-[5vw] w-1/2 h-[50vh] flex items-center justify-center place-items-center content-center">
      <ul
        className={
          "list-disc text-[1.5vw] space-y-[2vh] " +
          (props.light ? "text-white" : "text-[#45177D]")
        }
      >
        {listItems}
      </ul>
    </div>
  );

  return (
    <>
      <div className="md:flex hidden">
        {props.index % 2 == 0 ? (
          <>
            {part1}
            {part2}
          </>
        ) : (
          <>
            {part2}
            {part1}
          </>
        )}
      </div>

      <div className="m-4 md:hidden">
        <div className="flex sm:flex-row flex-col-reverse items-center justify-centers">
          {!props.light ? (
            <div className="w-[80%] sm:w-1/2  sm:h-[50vh] bg-[#68507a2b] sm:bg-transparent relative left-16 bottom-16 sm:left-0 xl:left-28 sm:bottom-0 z-[-2] flex place-items-center content-center">
              <ul className="list-disc pl-[8vw] pb-[1vh] pt-[10vw] text-[2.5vw] text-[#45177D]">
                {listItems}
              </ul>
            </div>
          ) : (
            <>
              <ul
                className={
                  "list-disc text-[0.8rem] md:text-[3vw] ml-[8vw] mb-[2vh] space-y-[1vh] text-white"
                }
              >
                {listItems}
              </ul>
            </>
          )}

          <div className="m-4 sm:p-[5vw] relative flex">
            {props.images[props.index].circle == "" ? (
              ""
            ) : (
              <img
                className="absolute w-[125px] sm:w-auto h-[125px] sm:h-[25vh] lg:h-[35vh] right-[0vw] left-[12rem] sm:left-auto z-[2] sm:right-[0vw] lg:right-[3vw] -top-[5vh] sm:top-0"
                src={props.images[props.index].circle}
                alt=""
              />
            )}

            <img
              className="w-[311px] h-[151px] sm:h-[35vh] lg:h-[45vh] sm:w-auto"
              src={props.images[props.index].card}
              alt=""
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default TextAndCard;
