import React from 'react';
import v1 from '../assets/videos/Rectangle 2614.png';
import v2 from '../assets/videos/Rectangle 2616.png';
import v3 from '../assets/videos/Rectangle 2619.png';
import v4 from '../assets/videos/Rectangle 2620.png';
import v5 from '../assets/videos/Rectangle 2621.png';
import v6 from '../assets/videos/Rectangle 2622.png';
import { Link } from 'react-router-dom';
const Videos = () => {
  return (
    <div className='lg:m-20 sm:m-10 m-6'>
      <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 lg:gap-16 sm:gap-12 gap-8 lg:p-8 sm:p-4 p-0" >
        <div className="flex flex-col justify-between lg:gap-y-0 gap-y-4 border rounded-xl  p-2 " style={{boxShadow: '4px 4px 4px 0px #00000040'}}>
            <div className='flex flex-col justify-between  gap-y-6 p-2'>
                <div><img src={v1} alt=""></img></div>
                <div className='text-[0.7rem] sm:text-[1rem] '>Network creation and visualization</div>
            </div>
                <Link to={'https://youtu.be/MpC1JecrleY'} className='flex w-full justify-end text-[0.7rem] sm:text-[1rem] text-[#8C025D] font-normal text-lg'>Watch video</Link>
        </div>
        <div className="flex flex-col justify-between lg:gap-y-0 gap-y-4 border rounded-xl  p-2 " style={{boxShadow: '4px 4px 4px 0px #00000040'}}>
            <div className='flex flex-col justify-between  gap-y-6 p-2'>
                <div><img src={v2} alt=""></img></div>
                <div className='text-[0.7rem] sm:text-[1rem] '>Single depot multiple route frequency setting and timetable development</div>

            </div>
                <Link to={'https://youtu.be/iK6eVxPce3U'} className='flex w-full justify-end text-[0.7rem] sm:text-[1rem] text-[#8C025D] font-normal text-lg'>Watch video</Link>
        </div>
        <div className="flex flex-col justify-between lg:gap-y-0 gap-y-4 border rounded-xl  p-2 " style={{boxShadow: '4px 4px 4px 0px #00000040'}}>
            <div className='flex flex-col justify-between  gap-y-6 p-2'>
                <div><img src={v3} alt=""></img></div>
                <div className='text-[0.7rem] sm:text-[1rem] '>Single and Multiple route sequential and simultaneous scheduling</div>

            </div>
                <Link to={'https://youtu.be/bf9O5zdNaac'} className='flex w-full justify-end text-[0.7rem] sm:text-[1rem] text-[#8C025D] font-normal text-lg'>Watch video</Link>
        </div>
        <div className="flex flex-col justify-between lg:gap-y-0 gap-y-4 border rounded-xl  p-2 " style={{boxShadow: '4px 4px 4px 0px #00000040'}}>
            <div className='flex flex-col justify-between  gap-y-6 p-2'>
                <div><img src={v4} alt=""></img></div>
                <div className='text-[0.7rem] sm:text-[1rem] '>Crew rostering</div>

            </div>
                <Link to={'https://youtu.be/bf9O5zdNaac'} className='flex w-full justify-end text-[0.7rem] sm:text-[1rem] text-[#8C025D] font-normal text-lg'>Watch video</Link>
        </div>
        <div className="flex flex-col justify-between lg:gap-y-0 gap-y-4 border rounded-xl  p-2 " style={{boxShadow: '4px 4px 4px 0px #00000040'}}>
            <div className='flex flex-col justify-between  gap-y-6 p-2'>
                <div><img src={v5} alt=""></img></div>
                <div className='text-[0.7rem] sm:text-[1rem] '>Dispatch, Control and Surveillance System</div>

            </div>
                <Link to={'https://youtu.be/07ccphHZto4'} className='flex w-full justify-end text-[0.7rem] sm:text-[1rem] text-[#8C025D] font-normal text-lg'>Watch video</Link>
        </div>
        <div className="flex flex-col justify-between lg:gap-y-0 gap-y-4 border rounded-xl  p-2 " style={{boxShadow: '4px 4px 4px 0px #00000040'}}>
            <div className='flex flex-col justify-between  gap-y-6 p-2'>
                <div><img src={v6} alt=""></img></div>
                <div className='text-[0.7rem] sm:text-[1rem] '>Drive console</div>

            </div>
                <Link to={'https://youtu.be/RSbIEsfNwjE'} className='flex w-full justify-end text-[0.7rem] sm:text-[1rem] text-[#8C025D] font-normal text-lg'>Watch video</Link>
        </div>
      </div>
    </div>
  )
}

export default Videos;
