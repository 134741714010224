import React from "react";

const VideoCard = ({ link }) => {
  return (
    <div className="w-full rounded-xl bg-white aspect-[806/453] opacity-80">
      <iframe
        className="w-full sm:h-fit h-[176px] aspect-[806/453] rounded-lg"
        src={link}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default VideoCard;
