import React from "react";
import citylabslogo from "../../assets/citylabs.png";
import linkedin from "../../assets/linkedin.svg";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>

    {/* Desktop */}
      <div id="footer" className="hidden w-auto h-full py-8 xl:py-4 md:flex flex-row gap-x-4 bg-gradient-to-r from-[#8A025D] to-[#45177D]">
        
        <div className="h-full w-[25%] pt-[3vh] pl-[5vw] flex flex-col">
          <img className="aspect-square w-[7vw]" src={citylabslogo} alt="" />

          <h3 className="leading-[1] mt-[1vh] text-white text-[12px] sm:text-base">
            City Future Lab
            <br />
            IIT Kharagpur, 2019
          </h3>

          <div className="font-bold text-[4vw] sm:text-[1.6vw] font-FiraSans text-white mt-[13vh] sm:mt-[15vh]">
            <h2>Follow us on</h2>
            <Link target="_blank" to={'https://www.linkedin.com/company/cityfuturelab/'} className="flex space-x-[2vw]">
              <img className="h-[3vh] sm:h-[5vh]" src={linkedin} alt="" />
            </Link>
          </div>
        </div>

        {/*  */}
        <div className="h-full text-white flex-grow font-FiraSans">
          <h1 className="mt-[3vh] pb-[1vh] text-[4vw] sm:text-[2vw] border-b mr-[5vw] border-white">
            Get in touch with us
          </h1>

          <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-y-4 sm:gap-y-12 lg:gap-y-[5.5rem] mt-4 xl:mt-[10vh] leading-tight">
            <div className="">
              <h1 className="underline text-[4vw] sm:text-[1.6vw]">Address</h1>
              <p className="mt-[1vh] text-[12px] sm:text-base">
                Department of Architecture and Regional Planning,
                <br />
                Indian Institute of Technology, Kharagpur,
                <br />
                West Bengal, India - 721302
              </p>
            </div>

            <div className="ml-0 sm:ml-[5vw]">
              <h1 className="text-[4vw] underline sm:text-[1.6vw]">
                Phone Number
              </h1>
              <p className="mt-[1vh]  sm:text-base">
                +91-3222283202
                <br />
                +91-9433944708
              </p>
            </div>

            <div className="mr-[5vw]">
              <h1 className="underline text-[4vw] sm:text-[1.6vw]">Email ID</h1>
              <p className="mt-[1vh] text-[11px] sm:text-base">
                debapratim.pandit@gmail.com
              </p>
            </div>
          </div>
        </div>
      </div>
    
    {/* Mobile */}
      <div id="footer" className="md:hidden w-auto h-full py-8 xl:py-4 flex flex-col gap-x-4 bg-gradient-to-r from-[#8A025D] to-[#45177D]">

        <div className="h-full w-full pt-[3vh] px-[5vw] flex flex-row justify-between">

          <div className="flex flex-col gap-y-1">
            <img className="aspect-square w-[7vw]" src={citylabslogo} alt="" />

            <h3 className="leading-[1] mt-[1vh] text-white text-[1.2rem] sm:text-lg">
              City Future Lab 
              IIT Kharagpur, 2019
            </h3>
          </div>

          <h1 className="mt-[3vh] h-fit pb-[1vh] text-white text-[1.1rem] sm:text-[1.3rem] border-b mr-[5vw] border-white">
            Get in touch with us
          </h1>

        </div>

        <div className="h-full text-white flex-grow font-FiraSans px-[5vw]">


          <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-y-4 sm:gap-y-12 lg:gap-y-[5.5rem] mt-4 xl:mt-[10vh] leading-tight">
            <div className="">
              <h1 className="underline text-[1.2rem] sm:text-[1.3rem]">Address</h1>
              <p className="mt-[1vh] text-[12px] sm:text-sm">
                Department of Architecture and Regional Planning,
                <br />
                Indian Institute of Technology, Kharagpur,
                <br />
                West Bengal, India - 721302
              </p>
            </div>

            <div className="ml-0 sm:ml-[5vw] text-end">
              <h1 className="underline text-[1.2rem] sm:text-[1.3rem]">
                Phone Number
              </h1>
              <p className="mt-[1vh]  sm:text-sm">
                +91-3222283202
                <br />
                +91-9433944708
              </p>
            </div>

            <div className="mr-[5vw]">
              <h1 className="underline text-[1.2rem] sm:text-[1.3rem]">Email ID</h1>
              <p className="mt-[1vh] text-[11px] sm:text-sm">
                debapratim.pandit@gmail.com
              </p>
            </div>

          </div>
        </div>

        <div className="font-bold text-[4vw] sm:text-[1.6vw] font-FiraSans text-white mt-8 px-[5vw]">
            <h2 className="text-[1.2rem]">Follow us on</h2>
            <Link target="_blank"  to={'https://www.linkedin.com/company/cityfuturelab/'} className="flex space-x-[2vw] mt-2">
              <img className="h-[3vh] sm:h-[5vh]" src={linkedin} alt="" />
            </Link>
          </div>

      </div>

    </>
  );
};

export default Footer;
