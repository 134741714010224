import React, { useState, useEffect } from "react";
import carousel1 from "../assets/carousel1.svg";
import carousel2 from "../assets/carousel2.svg";
import carousel3 from "../assets/carousel3.svg";
import leftarrow from "../assets/leftarrow.svg";
import rightarrow from "../assets/rightarrow.svg";
import FeatureBox from "../components/featureBox";
import ExtraInfo from "../components/extrainfo/extrainfo";
import { useNavigate } from "react-router-dom";
import VideoCard from "../components/video/VideoCard";
import feature1 from "../assets/features/feature1.png";
import feature2 from "../assets/features/feature2.png";
import feature3 from "../assets/features/feature3.png";
import feature4 from "../assets/features/feature4.png";
import feature5 from "../assets/features/feature5.png";
import feature6 from "../assets/features/feature6.png";
import feature7 from "../assets/features/feature7.png";
import { Link } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();

  const features = [
    {
      image: feature1,
      text: "Bus Service Planning and Operation Software",
    },
    {
      image: feature2,
      text: "Network creation and visualization",
    },
    {
      image: feature3,
      text: "Single depot multiple route frequency setting and timetable development",
    },
    {
      image: feature4,
      text: "Single and Multiple route sequential and simultaneous scheduling",
    },
    {
      image: feature5,
      text: "Crew rostering",
    },
    {
      image: feature6,
      text: "Dispatch, Control and Surveillance System",
    },
    {
      image: feature7,
      text: "Onboard Driver console",
    },
  ];

  const paragraphs = [
    "First software designed to build solutions synonymous to current manual practices of operation and management, with the advantages of artificial intelligence, information technology, and data visualization.",
    "Designed to control over speeding and bus bunching, improve fuel and fleet utilization, vacant seat reduction.",
    "Improved service reliability, comfort level, waiting time and eventually commuter satisfaction resulting in increased patronage in public transport.",
  ];

  const carouselSvgs = [carousel1, carousel2, carousel3];

  const [carouselIndex, setCarouselIndex] = useState(0);

  const handleLeftArrowClick = () => {
    let newIndex = 0;
    if (carouselIndex === 0) {
      newIndex = 2;
    } else {
      newIndex = carouselIndex - 1;
    }

    setCarouselIndex(newIndex);
  };

  const handleRightArrowClick = () => {
    let newIndex = (carouselIndex + 1) % 3;
    setCarouselIndex(newIndex);
  };

  useEffect(() => {
    let carouselMover = setInterval(() => {
      handleRightArrowClick();
    }, 2000);
    return () => clearInterval(carouselMover);
  });

  return (
    <div className="w-auto">
      <div className="w-auto relative md:hidden">
        <div className="bg-[#e32f6b14] w-[70%] h-[40vh] relative pt-[2.5vh]">
          <h3 className="text-left ml-[5vw] text-[4vw] font-[600] font-FiraSans text-[#8C025D]">
            PUBBS Transit v2.0
          </h3>
          <h4 className="ml-[4vw] text-[4vw] font-FiraSans text-[#45177D]">
            Integrated Planning and Operation Software for Bus Transit Operators
          </h4>
        </div>
        <div className=" absolute -bottom-[7vh] right-[4vw] rounded-xl bg-white ">
          <div className="w-full h-[2.5vh] bg-[#8C025D] rounded-t-xl"></div>
          <VideoCard link="https://www.youtube.com/embed/IsiD1oovee8?rel=0"></VideoCard>
        </div>
      </div>

      <div className="w-auto mt-[10vh] md:hidden">
        <div className="text-left font-FireSans w-auto flex justify-around px-[10vw]">
          <Link to="/videos">
            <button className="text-[#8C025D] font-[600] border-[#8C025D] py-[1.8vw] px-[3vw]  rounded-[1.5vw] border-[0.2vw] text-[2.5vw] hover:bg-gradient-to-r from-[#8C025D] to-[#45177D]  hover:text-white">
              WATCH MORE VIDEOS
            </button>
          </Link>

          <Link to="/form">
            <button className="text-white bg-gradient-to-r from-[#8C025D] to-[#45177D] font-[600] border-[#8C025D] py-[1.8vw] px-[3vw]  rounded-[1.5vw] border-[0.2vw] text-[2.5vw] hover:bg-gradient-to-l">
              REQUEST FOR DEMO
            </button>
          </Link>
        </div>
      </div>

      <div className="w-auto hidden md:flex h-[89vh]">
        <div className="sm:w-[40%] w-1/2 bg-[#E32F6B14] relative">
          <h3 className="text-left ml-[5vw] w-full pl-[2rem] sm:pl-[5.7vw] xl:pl-0 sm:mt-[5vh] xl:mt-[8vh] text-[4vw] sm:text-[2.5vw] xl:text-[2.2vw] font-[600] font-FiraSans text-[#8C025D]">
            PUBBS Transit v2.0
          </h3>
          <div className="rounded-xl absolute -right-[1rem] sm:-right-[5vw] top-[28vh] sm:top-[30vh] shadow-xl w-full sm:w-[40vw] h-[176px]">
            <div className="w-full h-[3vh] rounded-t-lg bg-[#8C025D]"></div>

            <div className="w-full rounded-b-xl bg-white ">
              {/* <iframe
                className="w-full sm:h-[20vw] h-[176px] rounded-b"
                src="https://www.youtube.com/embed/SySKzWjvFqc?autoplay=1&mute=1"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe> */}
              <VideoCard link="https://www.youtube.com/embed/IsiD1oovee8?rel=0"></VideoCard>
            </div>
          </div>
        </div>

        <div className="flex-grow ml-[5vw] flex flex-col h-full items-center sm:gap-y-4 justify-evenly sm:justify-center ">
          <h2 className="text-left font-FireSans leading-tight text-[#45177D] font-[700] text-[1.2rem] sm:text-[4vw] lg:text-[3.7vw]">
            Integrated Planning and
            <br />
            Operation Software for Bus
            <br />
            Transit Operators
          </h2>

          <div className="flex flex-col gap-y-4">
            {/* <p className="text-left leading-5 w-[150px] sm:w-[325px] lg:w-full sm:mr-0 lg:pl-[5.7vw] xl:pl-0 text-[1rem] sm:text-[2vw] lg:text-[1.4vw] xl:text-[1.1vw] font-extralight font-FiraSans text-[#45177D]">
              An initiative that aims at improving, enhancing, and easing out
              the bus
              <br />
              operation services within cities by making it more dynamic,
              optimized
              <br />
              and accurate for the operator and the users alike.
            </p> */}
          </div>

          <div className="text-left font-FireSans sm:mt-[3vh] w-3/4 lg:mt-[8vh] flex md:flex-row flex-col gap-y-4 md:space-x-[3vw] lg:pl-[5.7vw] md:w-full">
            <Link to="/videos">
              <button className="text-[#8C025D] font-[600] border-[#8C025D] py-[1.8vw] px-[3vw] sm:py-[1vw] sm:px-[2vw] rounded-[5vw] border-[0.2vw] text-[2.5vw] sm:text-[1.5vw] lg:text-[1vw] hover:bg-gradient-to-r from-[#8C025D] to-[#45177D] hover:text-white">
                WATCH MORE VIDEOS
              </button>
            </Link>

            <Link to="/form">
              <button className="text-white bg-gradient-to-r from-[#8C025D] to-[#45177D] font-[600] border-[#8C025D] py-[1.8vw] px-[3vw] sm:py-[1vw] sm:px-[2vw] rounded-[5vw] border-[0.2vw] text-[2.5vw] sm:text-[1.5vw] lg:text-[1vw] hover:bg-gradient-to-r hover:from-[#45177D] hover:to-[#8C025D]">
                REQUEST FOR DEMO
              </button>
            </Link>
          </div>
        </div>
      </div>

      <div className="py-[5.2vh] px-[4vw] sm:pb-[14.2vh]">
        <div className="relative h-[29vh] sm:h-[51vh] lg:h-[90vh] flex">
          {/* <img
            onClick={handleLeftArrowClick}
            className="absolute top-[20%] left-[-12px] sm:left-[-22px] lg:left-0 sm:top-[15%] lg:top-[25%] xl:top-[40%] cursor-pointer w-[45px] sm:w-[7vw] lg:w-[5vw]"
            src={leftarrow}
            alt=""
          /> */}

          <div className="flex flex-col justify-center relative left-8 sm:left-12 lg:left-20 xl:left-[11rem] h-[150px] w-[50%] sm:w-auto sm:h-[40vh] lg:h-[70vh]">
            <h3 className="text-left font-FireSans leading-tight text-[#5B2980] font-[700] text-[1rem] sm:text-[1.5rem] lg:text-[2rem]">
              PUBBS Transit v2.O
            </h3>

            <p className="text-[#45177D] sm:w-[35vw] lg:w-[33vw] font-[400] text-justify mt-[2vh] leading-tight text-[2vw] sm:text-[2vw] lg:text-[1.6vw]">
              {paragraphs[carouselIndex]}
            </p>
          </div>

          <div className="absolute top-[23vh] sm:top-[48vh] lg:top-[70vh] left-[11vh] sm:left-[17vw] lg:left-[28vw] flex space-x-[1vh] sm:space-x-[3vh]">
            <div
              className={
                "h-[2vw] sm:h-[1.1vw] border-[#45177D] border-[0.15vw] rounded-full aspect-square " +
                (carouselIndex === 0 ? "bg-[#45177D]" : "")
              }
            ></div>

            <div
              className={
                "h-[2vw] sm:h-[1.1vw] border-[#45177D] border-[0.15vw] rounded-full aspect-square " +
                (carouselIndex === 1 ? "bg-[#45177D]" : "")
              }
            ></div>

            <div
              className={
                "h-[2vw] sm:h-[1.1vw] border-[#45177D] border-[0.15vw] rounded-full aspect-square " +
                (carouselIndex === 2 ? "bg-[#45177D]" : "")
              }
            ></div>
          </div>

          <img
            src={carouselSvgs[carouselIndex]}
            alt=""
            className="h-[21vh] sm:h-[50vh] lg:h-[72vh] xl:h-[86vh] absolute right-0 rounded-lg"
          />

          <svg
            className="absolute right-0 z-[-1] h-[21vh] sm:h-[50vh] lg:h-[72vh] xl:h-[86vh] rounded-lg"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 940 800"
            fill="none"
          >
            <path
              d="M0 800C228 650 299.5 323.5 299.5 0H940V800H939.48H0Z"
              fill="#D4C6E5"
            />
          </svg>

          {/* <img
            onClick={handleRightArrowClick}
            className="absolute sm:right-0 right-[-13px] top-[20%] sm:top-[15%] lg:top-[25%] xl:top-[40%] cursor-pointer w-[45px] sm:w-[7vw] lg:w-[5vw]"
            src={rightarrow}
            alt=""
          /> */}
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-12 lg:gap-20 xl:gap-28 lg:px-12 xl:px-20">
          <div className="font-FiraSans h-[190px] px-4 py-2 sm:p-0 sm:h-full lg:h-[33vh] xl:h-[47vh] rounded-l-none rounded-t-3xl rounded-3xl bg-[#894472] flex flex-col justify-evenly sm:justify-normal">
            <h1 className="m-[3vw] text-white text-[3vw] sm:text-[1.5vw] ">
              Bus Service Planning and Operation Software with focus on:
            </h1>
            <ul className="list-disc mx-[5vw] text-white text-[2.4vw] sm:text-[1.2vw] flex flex-col gap-y-[0.3rem] md:gap-y-0">
              <li>Bus operators’ profit and operation efficiency</li>
              <li>Comfort and convenience of public bus transport users</li>
              <li>Customizable as per regional and local context and goals</li>
            </ul>
          </div>

          <div className="mb-[5vh] font-FiraSans h-[190px] px-4 py-2 sm:p-0 sm:h-full lg:h-[33vh] xl:h-[47vh] rounded-l-3xl rounded-b-none  rounded-3xl bg-[#4B3369] z-10 flex flex-col justify-evenly sm:justify-normal">
            <h1 className="m-[2vw] sm:m-[3vw] text-white text-[3vw] sm:text-[1.5vw] ">
              System built on extensive datasets for accurate predictions for:
            </h1>

            <ul className="list-disc mx-[5vw] text-white text-[2.4vw] sm:text-[1.2vw] flex flex-col gap-y-[0.3rem] md:gap-y-0">
              <li>Bus and crew schedules</li>
              <li>Fleet size and Service frequency.</li>
              <li>
                Long and short term travel demand assessment in the most
                convenient and automated manner.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="bg-[#68507a2b]">
        <h1 className="text-5xl text-[#45177D] font-FiraSans pt-16 pl-16">
          Features
        </h1>
        <div className="w-full p-[3vw] h-[30vh] sm:h-[100vh] xl:h-[110vh]  flex gap-x-6 gap-y-4 overflow-x-scroll no-scrollbar sm:grid sm:grid-cols-3 lg:grid-cols-4 place-items-center content-evenly">
          <div
            onClick={() => {
              navigate("/software");
            }}
            className="group cursor-pointer w-full h-[140px] sm:h-auto  rounded-lg aspect-[358/313] flex flex-col shadow-md bg-white"
          >
            <img src={features[0].image} alt=""/>
            <h1 className="text-[#45177D] w-full text-center leading-tight flex flex-col text-[2vw] sm:text-[1.5vw] lg:text-[1vw] flex-grow justify-center">
              {features[0].text}
            </h1>
          </div>

          <div
            onClick={() => {
              navigate("/network");
            }}
            className="cursor-pointer w-full h-[140px] sm:h-auto rounded-lg aspect-[358/313] flex flex-col shadow-md bg-white"
          >
            <img src={features[1].image} alt="" />
            <h1 className="text-[#45177D] w-full text-center leading-tight flex flex-col text-[2vw] sm:text-[1.5vw] lg:text-[1vw] flex-grow justify-center">
              {features[1].text}
            </h1>
          </div>

          <div
            onClick={() => {
              navigate("/frequency");
            }}
            className="cursor-pointer w-full h-[140px] sm:h-auto rounded-lg aspect-[358/313] flex flex-col shadow-md bg-white"
          >
            <img src={features[2].image} alt="" />
            <h1 className="text-[#45177D] w-full text-center leading-tight flex flex-col text-[2vw] sm:text-[1.5vw] lg:text-[1vw] flex-grow justify-center">
              {features[2].text}
            </h1>
          </div>

          <div
            onClick={() => {
              navigate("/scheduling");
            }}
            className="cursor-pointer w-full h-[140px] sm:h-auto rounded-lg aspect-[358/313] flex flex-col shadow-md bg-white"
          >
            <img src={features[3].image} alt="" />
            <h1 className="text-[#45177D] w-full text-center leading-tight flex flex-col text-[2vw] sm:text-[1.5vw] lg:text-[1vw] flex-grow justify-center">
              {features[3].text}
            </h1>
          </div>

          <div
            onClick={() => {
              navigate("/crew");
            }}
            className="cursor-pointer w-full h-[140px] sm:h-auto rounded-lg aspect-[358/313] flex flex-col shadow-md bg-white"
          >
            <img src={features[4].image} alt="" />
            <h1 className="text-[#45177D] w-full text-center leading-tight flex flex-col text-[2vw] sm:text-[1.5vw] lg:text-[1vw] flex-grow justify-center">
              {features[4].text}
            </h1>
          </div>

          <div
            onClick={() => {
              navigate("/control");
            }}
            className="cursor-pointer w-full h-[140px] sm:h-auto rounded-lg aspect-[358/313] flex flex-col shadow-md bg-white"
          >
            <img src={features[5].image} alt="" />
            <h1 className="text-[#45177D] w-full text-center leading-tight flex flex-col text-[2vw] sm:text-[1.5vw] lg:text-[1vw] flex-grow justify-center">
              {features[5].text}
            </h1>
          </div>

          <div
            onClick={() => {
              navigate("/driver");
            }}
            className="cursor-pointer w-full h-[140px] sm:h-auto rounded-lg aspect-[358/313] flex flex-col shadow-md bg-white"
          >
            <img src={features[6].image} alt="" />
            <h1 className="text-[#45177D] w-full text-center leading-tight flex flex-col text-[2vw] sm:text-[1.5vw] lg:text-[1vw] flex-grow justify-center">
              {features[6].text}
            </h1>
          </div>
        </div>
      </div>

      <ExtraInfo></ExtraInfo>
    </div>
  );
};

export default Home;
