import React, { useState } from "react";
import logo from "../../assets/pubbs-logo.png";
import hamburger from "../../assets/Vector.png";
import expand from "../../assets/expand.png";
import { useNavigate } from "react-router-dom";
import { easeInOut, motion } from "framer-motion";

const Header = () => {
  const navigate = useNavigate();
  const [isopen, setisopen] = useState(false);
  const [nav, setnav] = useState(false);

  return (
    <>
      <div 
      onClick={() => {
        
      }}
      className="w-auto space-x-[1.5vw] text-[2vh] px-[2vw] h-[10.5vh] items-center justify-between text-white bg-gradient-to-r flex from-[#8C025D] to-[#45177D]">
        <img
          onClick={() => {
            navigate("/");
            setisopen(false)
          }}
          className="absolute lg:w-[6.1vw] top-[1.8vh] left-[2.5vw] w-[75px] cursor-pointer"
          src={logo}
          alt=""
        />

        <div className="flex-grow"></div>

        <button
          onClick={() => {
            navigate("/");
            setisopen(false)
          }}
          className="hidden lg:flex flex-col group"
        >
          Home
          <span className="h-[0.05rem] w-full bg-white hidden group-focus:inline-block"></span>
        </button>

        <button
          onClick={() => {
            navigate("/software");
            setisopen(false)
          }}
          className="hidden lg:flex flex-col group"
        >
          Software
          <span className="h-[0.05rem] w-full bg-white hidden group-focus:inline-block"></span>
        </button>
        <button
          className="hidden lg:flex flex-col group"
          onClick={() => {
            navigate("/network");
            setisopen(false)
          }}
        >
          Network
          <span className="h-[0.05rem] w-full bg-white hidden group-focus:inline-block"></span>
        </button>

        <button
          className="hidden lg:flex flex-col group"
          onClick={() => {
            navigate("/frequency");
            setisopen(false)
          }}
        >
          Frequency Setting
          <span className="h-[0.05rem] w-full bg-white hidden group-focus:inline-block"></span>
        </button>

        <button
          onClick={() => {
            navigate("/scheduling");
            setisopen(false)
          }}
          className="hidden lg:flex flex-col group"
        >
          Scheduling
          <span className="h-[0.05rem] w-full bg-white hidden group-focus:inline-block"></span>
        </button>

        <button
          className="hidden lg:flex flex-col group"
          onClick={() => {
            navigate("/crew");
            setisopen(false)
          }}
        >
          Crew Rostering
          <span className="h-[0.05rem] w-full bg-white hidden group-focus:inline-block"></span>
        </button>

        <button
          onClick={() => {
            navigate("/control");
            setisopen(false)
          }}
          className="hidden lg:flex flex-col group"
        >
          Control System
          <span className="h-[0.05rem] w-full bg-white hidden group-focus:inline-block"></span>
        </button>

        <button
          className="hidden lg:flex flex-col group"
          onClick={() => {
            navigate("/driver");
            setisopen(false)
          }}
        >
          Driver Console
          <span className="h-[0.05rem] w-full bg-white hidden group-focus:inline-block"></span>
        </button>

        <button
          className="hidden lg:flex gap-x-[6px] items-center"
          onClick={() => {
            setisopen(!isopen);
          }}
        >
          Resources <img src={expand} alt="" className="w-[20px]" />
        </button>

        {isopen && (
          <motion.div
            onClick={() => {
              setisopen(!isopen);
            }}
            initial={{
              x: 0,
              opacity: 0,
              y: -10,
              scale: 0.95,
              transitionDuration: 0.1,
            }}
            animate={{
              x: 0,
              opacity: 1,
              scale: 1,
              transitionDuration: 0.3,
              transitionTimingFunction: easeInOut,
            }}
            exit={{
              x: 0,
              opacity: 0,
              y: -10,
              scale: 1,
              transitionDuration: 0.1,
            }}
            className={`hidden lg:flex flex-col gap-y-1 w-[340px] sm:w-[375px] absolute right-0 top-[80px] z-50`}
          >
            <div
              onClick={() => {
                navigate("/resources-software");
                setisopen(!isopen)
              }}
              className="cursor-pointer h-[45px] bg-[#DCBECE] text-[#1E0431] font-normal text-base font-sans sm:text-xl flex items-center px-8"
            >
              <span>Software</span>
            </div>

            <div
              onClick={() => {
                navigate("/research");
                setisopen(!isopen)
              }}
              className="cursor-pointer h-[45px] bg-[#DCBECE] text-[#1E0431] font-normal text-base font-sans sm:text-xl flex items-center px-8"
            >
              <span>Research Project</span>
            </div>

            <div
              onClick={() => {
                navigate("/publications");
                setisopen(!isopen)
              }}
              className="cursor-pointer h-[45px] bg-[#DCBECE] text-[#1E0431] font-normal text-base font-sans sm:text-xl flex items-center px-8"
            >
              <span>Publication</span>
            </div>

            <div
              onClick={() => {
                navigate("/caseStudy");
                setisopen(!isopen)
              }}
              className="cursor-pointer h-[45px] bg-[#DCBECE] text-[#1E0431] font-normal text-base font-sans sm:text-xl flex items-center px-8">
              <span>Case Study</span>
            </div>

            <div onClick={() => {
              navigate("/videos");
              setisopen(!isopen)
            }}
              className="cursor-pointer h-[45px] bg-[#DCBECE] text-[#1E0431] font-normal text-base font-sans sm:text-xl flex items-center px-8">
              <span>Videos</span>
            </div>
          </motion.div>
        )}

        <button
          onClick={() => {
            navigate("/electric");
            setisopen(false)
          }}
          className="hidden lg:flex flex-col group">
            Electric Bus
            <span className="h-[0.05rem] w-full bg-white hidden group-focus:inline-block"></span>
          </button>

        <button 
        onClick={() => {
          setisopen(false)
        }}
        className="hidden lg:flex flex-col group">
          <a href="#footer">Contact</a>
          <span className="h-[0.05rem] w-full bg-white hidden group-focus:inline-block"></span>
          </button>

        <div className="w-[37px] h-[37px] flex items-center justify-center lg:hidden cursor-pointer">
          <img
            src={hamburger}
            alt=""
            onClick={() => {
              setnav(!nav);
            }}
          />
        </div>
      </div>

      {/* Navbar Mobile */}
      {nav && (
        <motion.div
          initial={{
            x: 0,
            opacity: 0,
            y: -10,
            scale: 0.95,
            transitionDuration: 0.1,
          }}
          animate={{
            x: 0,
            opacity: 1,
            scale: 1,
            transitionDuration: 0.3,
            transitionTimingFunction: easeInOut,
          }}
          exit={{ x: 0, opacity: 0, y: -10, scale: 1, transitionDuration: 0.1 }}

          className="flex flex-col w-full items-end gap-y-[0.1rem] lg:hidden absolute z-[50] top-[5.3rem]"
        >
          <div className="flex flex-col gap-y-[0.1rem] bg-gray-200 cursor-pointer">

            <div
              onClick={() => {
                navigate("/");
                setnav(!nav);
              }}

              className="w-[340px] sm:w-[375px] h-[45px] bg-[#894472] text-[#FFFFFF] font-normal text-base font-sans sm:text-xl flex items-center px-8"
            >
              <span>Home</span>
            </div>

            <div
              onClick={() => {
                navigate("/software");
                setnav(!nav);
              }}

              className="w-[340px] sm:w-[375px] h-[45px] bg-[#894472] text-[#FFFFFF] font-normal text-base font-sans sm:text-xl flex items-center px-8"
            >
              <span>Software</span>
            </div>

            <div
              onClick={() => {
                navigate("/network");
                setnav(!nav);
              }}
              className="w-[340px] sm:w-[375px] h-[45px] bg-[#894472] text-[#FFFFFF] font-normal text-base font-sans sm:text-xl flex items-center px-8"
            >
              <span>Network</span>
            </div>

            <div
              onClick={() => {
                navigate("/frequency");
                setnav(!nav);
              }}
              className="w-[340px] sm:w-[375px] h-[45px] bg-[#894472] text-[#FFFFFF] font-normal text-base font-sans sm:text-xl flex items-center px-8"
            >
              <span>Frequency Setting</span>
            </div>

            <div
              onClick={() => {
                navigate("/scheduling");
                setnav(!nav);
              }}
              className="w-[340px] sm:w-[375px] h-[45px] bg-[#894472] text-[#FFFFFF] font-normal text-base font-sans sm:text-xl flex items-center px-8"
            >
              <span>Scheduling</span>
            </div>

            <div
              onClick={() => {
                navigate("/crew");
                setnav(!nav);
              }}
              className="w-[340px] sm:w-[375px] h-[45px] bg-[#894472] text-[#FFFFFF] font-normal text-base font-sans sm:text-xl flex items-center px-8"
            >
              <span>Crew Rostering</span>
            </div>

            <div
              onClick={() => {
                navigate("/control");
                setnav(!nav);
              }}
              className="w-[340px] sm:w-[375px] h-[45px] bg-[#894472] text-[#FFFFFF] font-normal text-base font-sans sm:text-xl flex items-center px-8"
            >
              <span>Control System</span>
            </div>

            <div
              onClick={() => {
                navigate("/driver");
                setnav(!nav);
              }}
              className="w-[340px] sm:w-[375px] h-[45px] bg-[#894472] text-[#FFFFFF] font-normal text-base font-sans sm:text-xl flex items-center px-8"
            >
              <span>Driver Console</span>
            </div>

            <div className="w-[340px] sm:w-[375px] h-[45px] bg-[#894472] text-[#FFFFFF] font-normal text-base font-sans sm:text-xl flex items-center px-8">
              <span
                className="flex justify-between w-full"
                onClick={() => {
                  setisopen(!isopen);
                }}
              >
                Resources <img src={expand} alt="" />
              </span>
            </div>

            {isopen && (
              <motion.div
                initial={{
                  x: 0,
                  opacity: 0,
                  y: -10,
                  scale: 0.95,
                  transitionDuration: 0.1,
                }}
                animate={{
                  x: 0,
                  y: 0,
                  opacity: 1,
                  scale: 1,
                  transitionDuration: 0.3,
                  transitionTimingFunction: easeInOut,
                }}
                exit={{
                  x: 0,
                  opacity: 0,
                  y: -10,
                  scale: 1,
                  transitionDuration: 0.1,
                }}
                className={`flex flex-col gap-y-[0.1rem] w-[340px] sm:w-[375px]`}
              >
                <div
                  onClick={() => {
                    navigate("/resources-software");
                    setnav(!nav);
                  }}
                  className="h-[45px] bg-[#DCBECE] text-[#1E0431] font-normal text-base font-sans sm:text-xl flex items-center px-8"
                >
                  <span>Software</span>
                </div>

                <div
                  onClick={() => {
                    navigate("/research");
                    setnav(!nav);
                  }}
                  className="h-[45px] bg-[#DCBECE] text-[#1E0431] font-normal text-base font-sans sm:text-xl flex items-center px-8"
                >
                  <span>Research Project</span>
                </div>

                <div
                  onClick={() => {
                    navigate("/publications");
                    setnav(!nav);
                  }}
                  className="h-[45px] bg-[#DCBECE] text-[#1E0431] font-normal text-base font-sans sm:text-xl flex items-center px-8"
                >
                  <span>Publication</span>
                </div>

                <div
                  onClick={() => {
                    navigate("/caseStudy");
                    setnav(!nav);
                  }}
                  className="h-[45px] bg-[#DCBECE] text-[#1E0431] font-normal text-base font-sans sm:text-xl flex items-center px-8">
                  <span>Case Study</span>
                </div>

                <div onClick={() => {
                  navigate("/videos");
                  setnav(!nav);
                }}
                  className="h-[45px] bg-[#DCBECE] text-[#1E0431] font-normal text-base font-sans sm:text-xl flex items-center px-8">
                  <span>Videos</span>
                </div>
              </motion.div>
            )}

            <div
              onClick={() => {
                navigate("/electric");
                setnav(!nav)
              }}
              className="w-[340px] sm:w-[375px] h-[45px] bg-[#894472] text-[#FFFFFF] font-normal text-base font-sans sm:text-xl flex items-center px-8">
                Electric Bus
            </div>

            <div
              onClick={() => {
                setnav(!nav);
              }}
              className="w-[340px] sm:w-[375px] h-[45px] bg-[#894472] text-[#FFFFFF] font-normal text-base font-sans sm:text-xl flex items-center px-8">
              <span><a href="#footer">Contact</a></span>
            </div>
          </div>
        </motion.div>
      )}
    </>
  );
};

export default Header;
