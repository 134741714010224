import React from "react";
import VideoCard from "../components/video/VideoCard";
import TextAndCard from "../components/universal/TextAndCard";
import card2 from '../assets/Group 4778.png'
import circle2 from '../assets/Ellipse 81 (1).png'

let images = [
  { card: "assets/software/card1.png", circle: "assets/software/circle1.png" },
  { card: card2, circle: circle2}
];

let texts = [
  [
    "Period wise frequency setting using ride check method",
    "Test multiple frequency setting scenarios by making subtle changes form system generated solutions",
    "Simulation based optimization approach to mimic actual conditions throughout the day and finding the best solution",
  ],
  [
    "Create timetables, dispatch sequence, stop timetable and calculate cost of bus operations",
    "Determine fleet sizes required and their utilization",
    "Advance ridership prediction models integrating LAND USE & ETM data",
  ],
  [],
];

const Frequency = () => {
  return (
    <div className="w-auto font-FiraSans leading-tight">
      <div className="w-auto md:h-[100vh] h-[80vw] flex">
        <div className="md:h-[80vh] h-[50vw] relative w-[80%] bg-[#E32F6B14]">
          <div className="w-[270px] sm:w-[380px] lg:w-[530px] xl:w-[600px] absolute -right-[18vw] sm:-bottom-[12vh] -bottom-[14vh]  shadow-lg rounded-xl">
            <VideoCard link="https://www.youtube.com/embed/iK6eVxPce3U?rel=0"></VideoCard>
          </div>
          <h2 className="text-left pl-[5vw] pt-[5vh] md:pt-[10vh] font-FireSans leading-tight text-[#45177D] font-[700] text-[4.7vw] md:text-[3.7vw]">
            Single depot multiple route frequency setting and timetable
            development
          </h2>
          <p className="md:block hidden  w-[40vw] text-justify text-[#45177D] ml-[5vw] mt-[5vw] leading-normal font-light">
            Bus service design based on user perception and expectation of
            service attributes such as crowding level, waiting time, travel
            time, etc. are important in maintaining the attractiveness of the
            bus transit. Similarly, operators need to keep their costs down to
            ensure profitability. Thus operator costs such as fuel cost, vehicle
            maintenance cost, vehicle depreciation cost, staff wages, etc. are
            considered along with user costs. Additionally, penalty cost for the
            passenger not served is given consideration since it increases
            inconvenience for the users and fare loss for the operators.
          </p>
        </div>
      </div>
      <p className="md:hidden text-[3vw] mb-[8vw] w-[90vw] text-justify text-[#45177D] ml-[5vw] mt-[5vw] leading-tight font-light">
        Bus service design based on user perception and expectation of service
        attributes such as crowding level, waiting time, travel time, etc. are
        important in maintaining the attractiveness of the bus transit.
        Similarly, operators need to keep their costs down to ensure
        profitability. Thus operator costs such as fuel cost, vehicle
        maintenance cost, vehicle depreciation cost, staff wages, etc. are
        considered along with user costs. Additionally, penalty cost for the
        passenger not served is given consideration since it increases
        inconvenience for the users and fare loss for the operators.
      </p>
      <TextAndCard texts={texts} images={images} index={0}></TextAndCard>
      <div className="w-auto text-white font-FiraSans flex flex-col place-items-center bg-[#894472]">
        <h1 className="m-[4vw] mb-0 md:text-[2vw] font-bold tracking-wider">
          Extensive list of constraints for ensuring equity, efficiency and
          service quality
        </h1>
        <ul className="list-disc grid md:grid-cols-2 w-full md:place-items-center space-y-[2vh] p-[5vw] pt-[3vw]">
          <li className="md:w-2/3 ml-[5vw]">
            Maximum on board crowding level for each trip
          </li>
          <li className="md:w-2/3 ml-[5vw]">Maximum passenger waiting time</li>
          <li className="md:w-2/3 ml-[5vw]">
            Minimum crowding level for each trip
          </li>
          <li className="md:w-2/3 ml-[5vw]">Minimum passenger waiting time</li>
          <li className="md:w-2/3 ml-[5vw]">Minimum passengers per trip</li>
          <li className="md:w-2/3 ml-[5vw]">
            Maximum operation cost per passenger
          </li>
          <li className="md:w-2/3 ml-[5vw]">
            Maximum percentage of passenger lost
          </li>
          <li className="md:w-2/3 ml-[5vw]">Minimum cost recovery ratio</li>
          <li className="md:w-2/3 ml-[5vw]">Maximum fleet size</li>
          <li className="md:w-2/3 ml-[5vw]">Maximum number of engaged buses</li>
          <li className="md:w-2/3 ml-[5vw]">And many others</li>
          <li className="md:w-2/3 ml-[5vw]">Fleet size</li>
        </ul>
      </div>
      <TextAndCard texts={texts} images={images} index={1}></TextAndCard>
    </div>
  );
};

export default Frequency;
