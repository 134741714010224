import React from 'react'
import chart from '../assets/caseStudy/image 84.png';
import graph from '../assets/caseStudy/image 83.png';
import Img1 from "../assets/caseStudy/image 85.png";
import Img2 from "../assets/caseStudy/image 86.png";
import Img3 from "../assets/caseStudy/image 87.png";
import Img4 from "../assets/caseStudy/image 88.png";
import Img5 from "../assets/caseStudy/image 89.png";
import Img6 from "../assets/caseStudy/image 90.png";

const CaseStudy = () => {
  return (
    <div>
        <div className='m-4 md:m-8 lg:m-12'>

            {/* Heading */}
            <div className='flex flex-col gap-y-3 max-w-[560px]'>

                <h1 className='text-[#8C025D] font-semibold md:text-4xl text-3xl'>S21 Bus Route - Kolkata</h1>

                <p className='font-normal text-[#45177D] md:text-lg text-base'>S21 is a 22 km long route that starts from the Bagbazaar bus stop and passes through 12 other on-route stops to connect with the Garia bus terminal.</p>

            </div>

            <div className='flex md:flex-row flex-col gap-8 items-center justify-center md:py-12 md:px-0 lg:p-12 py-12 px-0'>

              <div className='flex flex-col gap-y-4'>

                <h1 className='text-[#45177D] font-normal md:text-2xl text-xl text-center'>Landuse distribution data along S21 route</h1>

                <img src={chart} alt="" />

              </div>

              <div>
                
                <img src={graph} alt="" />

              </div>

            </div>

            <div>

              <h1 className='font-medium text-xl sm:text-2xl text-[#45177D] underline'>Results</h1>

              <div className='grid md:grid-cols-2 grid-cols-1 gap-12 md:p-8 p-3'>

                <div className='flex flex-col gap-y-3 items-center justify-center lg:p-12 '>

                  <img src={Img1} alt="" />

                  <h1 className='text-lg font-medium text-[#45177D] text-center'>Network visualization on software screen</h1>

                </div>

                <div className='flex flex-col gap-y-3 items-center justify-center lg:p-12'>

                  <img src={Img2} alt="" />

                  <h1 className='text-lg font-medium text-[#45177D] text-center'>Time table development and bus assignment for route on software screen</h1>

                </div>

                <div className='flex flex-col gap-y-3 items-center justify-center'>

                  <img src={Img3} alt="" />

                  <h1 className='text-lg font-medium text-[#45177D] text-center'>Space Time Chart for optimised frequency down direction</h1>
                  
                </div>

                <div className='flex flex-col gap-y-3 items-center justify-center'>

                  <img src={Img4} alt="" />

                  <h1 className='text-lg font-medium text-[#45177D] text-center'>Space Time Chart for optimised frequency up direction</h1>

                </div>

                <div className='flex flex-col gap-y-3 items-center justify-center'>

                  <img src={Img5} alt="" />

                  <h1 className='text-lg font-medium text-[#45177D] text-center'>Cost calculations DN direction </h1>

                </div>
                
                <div className='flex flex-col gap-y-3 items-center justify-center'>

                  <img src={Img6} alt="" />

                  <h1 className='text-lg font-medium text-[#45177D] text-center'>Cost calculations UP direction</h1>
                </div>
              </div>
            </div>

        </div>

    </div>
  )
}

export default CaseStudy