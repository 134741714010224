import React from "react";
import ImnagTextCard from "../components/network/ImnagTextCard";
import card2 from "../assets/Network/card2.png";
import circle2 from "../assets/Network/circle2.png";
import card3 from "../assets/Network/card3.png";
import circle3 from "../assets/Network/circle3.png";
import card4 from "../assets/Network/card4.png";
import circle4 from "../assets/Network/circle4.png";
import VideoCard from "../components/video/VideoCard";

const Network = () => {
  return (
    <div className="w-auto font-FiraSans leading-tight">
      <div className="w-full sm:w-auto sm:h-[80vh] h-[60vh] flex">
        <div className="h-[40vh] sm:h-[60vh] relative w-[80%] bg-[#E32F6B14]">
          <div className="w-[270px] sm:w-[500px] lg:w-[600px] absolute -right-[18vw] -bottom-[6vh] sm:-right-[17vw] lg:-right-[7vw] sm:-bottom-[15vh] shadow-lg rounded-xl">
            <VideoCard link="https://www.youtube.com/embed/MpC1JecrleY?mute=1"></VideoCard>
          </div>

          <h2 className="text-left pl-[5vw] pt-[10vh] font-FireSans leading-tight text-[#45177D] font-[700] text-[6.7vw] sm:text-[5.7vw] lg:text-[3.7vw]">
            Network Creation and
            <br />
            Visualization
          </h2>
        </div>
      </div>

      <div className="md:m-4">
        <ImnagTextCard></ImnagTextCard>

        <div className="flex md:flex-row flex-col-reverse overflow-x-hidden">
          <div className="p-[3vw] w-[95%] md:w-1/2 h-[200px] md:h-[50vh] bg-[#68507a2b] md:bg-transparent relative left-8 bottom-16 md:left-0 md:bottom-0 z-[-2] flex place-items-center content-center">
            <ul className="list-disc-none sm:list-disc text-[0.8rem] md:text-[1rem] lg:text-[1.5vw] space-y-[2vh] text-[#45177D]">
              <li>
                Build your route network connecting stops in UP and Down
                direction.
              </li>

              <li>
                Advance ridership prediction models integrating LAND USE & ETM
                data.
              </li>
            </ul>
          </div>

          <div className="p-[3vw] relative flex">
            <img
              className="absolute w-[150px] md:w-auto h-[150px] md:h-[30vh] left-[11.5rem] md:left-auto z-[2] md:right-[0vw] lg:right-[1vw] top-[0vw] lg:top-[2vw]"
              src={circle2}
              alt=""
            />

            <img
              className="w-[311px] h-[200px] md:h-[50vh] md:w-auto"
              src={card2}
              alt=""
            />
          </div>
        </div>

        <div className="flex md:flex-row flex-col overflow-x-hidden">
          <div className="p-[3vw] relative">
            <img
              className="absolute w-[150px] md:w-auto h-[150px] md:h-[30vh] left-[11.5rem] md:left-auto z-[2] md:right-[0vw] lg:right-[1vw] top-[0vw] lg:top-[2vw]"
              src={circle3}
              alt=""
            />

            <img
              className="w-[311px] h-[200px] md:h-[50vh] md:w-auto"
              src={card3}
              alt=""
            />
          </div>

          <div className="p-[3vw] w-[95%] md:w-1/2 h-[200px] md:h-[50vh] bg-[#68507a2b] md:bg-transparent relative left-8 bottom-16 md:left-0 md:bottom-0 z-[-2] flex place-items-center content-center">
            <ul className="list-disc-none sm:list-disc text-[0.8rem] md:text-[1rem] lg:text-[1.5vw] space-y-[2vh] text-[#45177D]">
              <li>
                Bus travel time prediction at different times of the day using
                artificial intelligence.
              </li>
            </ul>
          </div>
        </div>

        <div className="flex md:flex-row flex-col-reverse overflow-x-hidden">
          <div className="p-[3vw] w-[95%] md:w-1/2 h-[200px] md:h-[50vh] bg-[#68507a2b] md:bg-transparent relative left-8 bottom-16 md:left-0 md:bottom-0 z-[-2] flex place-items-center content-center">
            <ul className="list-disc-none sm:list-disc text-[0.8rem] md:text-[1rem] lg:text-[1.5vw] space-y-[2vh] text-[#45177D]">
              <li>Visualize route network served by each depot.</li>
              <li>Bus stop details</li>
            </ul>
          </div>

          <div className="p-[3vw] relative">
            <img
              className="absolute w-[150px] md:w-auto h-[150px] md:h-[30vh] left-[11.5rem] md:left-auto z-[2] md:right-[0vw] lg:right-[1vw] top-[0vw] lg:top-[2vw]"
              src={circle4}
              alt=""
            />

            <img
              className="w-[311px] h-[200px] md:h-[50vh] md:w-auto"
              src={card4}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Network;
