import React from "react";
import VideoCard from "../components/video/VideoCard";
import circle from "../assets/crew/Ellipse 81.png";
import card from "../assets/crew/card1.png";

const Crew = () => {
  return (
    <div className="w-auto font-FiraSans leading-tight">
      <div className="w-full sm:w-auto sm:h-[80vh] h-[60vh] flex">
        <div className="h-[40vh] sm:h-[60vh] relative w-[80%] bg-[#E32F6B14]">
          <div className="w-[270px] sm:w-[500px] lg:w-[600px] absolute -right-[18vw] -bottom-[6vh] sm:-right-[17vw] lg:-right-[7vw] sm:-bottom-[15vh] shadow-lg rounded-xl">
            <VideoCard link="https://www.youtube.com/embed/bf9O5zdNaac?rel=0"></VideoCard>
          </div>

          <h2 className="text-left pl-[5vw] pt-[10vh] font-FireSans leading-tight text-[#45177D] font-[700] text-[6.7vw] sm:text-[5.7vw] lg:text-[3.7vw]">
            Crew rostering
          </h2>
        </div>
      </div>

      <div className="m-4">
        <div className="flex sm:flex-row flex-col-reverse">
          <div className="m-4 w-[80%] sm:w-1/2 h-[150px] sm:h-[50vh] bg-[#68507a2b] sm:bg-transparent relative left-16 bottom-16 sm:left-0 xl:left-28 sm:bottom-0 z-[-2] flex place-items-center content-center">
            <ul className="list-disc p-12 sm:p-4 text-[1rem] sm:text-[1.4rem] lg:text-[1.5vw] space-y-[2vh] text-[#45177D]">
              <li>Shift wise crew requirement and rostering .</li>
            </ul>
          </div>

          <div className="m-4 sm:p-[5vw] relative flex">
            <img
              className="absolute w-[125px] sm:w-auto h-[125px] sm:h-[25vh] lg:h-[35vh] left-[12rem] sm:left-auto z-[2] sm:right-[0vw] lg:right-[3vw] -top-8 sm:top-0"
              src={circle}
              alt=""
            />

            <img
              className="w-[311px] h-[151px] sm:h-[35vh] lg:h-[45vh] sm:w-auto"
              src={card}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Crew;
