import React from "react";
import VideoCard from "../components/video/VideoCard";
import TextAndCard from "../components/universal/TextAndCard";

let images = [
  {
    card: "assets/scheduling/card1.png",
    circle: "assets/scheduling/circle1.png",
  },
  { card: "assets/scheduling/card2.png", circle: "" },
];

let texts = [
  [
    "Schedule visualization",
    "Multiple vehicle dispatch approaches",
    "LIFO  FIFO  Random",
  ],
  [
    "Vehicle allocation and utilization details",
    "Full day vehicle schedule for either single routes or multiple routes",
  ],
  [],
];

const Scheduling = () => {
  return (
    <div className="w-auto font-FiraSans leading-tight">
      <div className="w-auto md:h-[90vh] h-[90vw] flex">
        <div className="md:h-[60vh] h-[50vw] relative w-[80%] bg-[#E32F6B14]">
          <div className="w-[270px] sm:w-[500px] lg:w-[600px] absolute -right-[15vw] -bottom-[15vh] sm:-bottom-[16vh] md:-bottom-[10vh] lg:-bottom-[15vh] xl:-bottom-[20vh] shadow-lg rounded-xl">
            <VideoCard link="https://www.youtube.com/embed/bf9O5zdNaac?rel=0"></VideoCard>
          </div>
          <h2 className="text-left pl-[5vw] pt-[5vh] md:pt-[10vh] font-FireSans leading-tight text-[#45177D] font-[700] text-[4.7vw] md:text-[3.7vw]">
            Single and Multiple route sequential and simultaneous scheduling
          </h2>
        </div>
      </div>
      <TextAndCard texts={texts} images={images} index={0}></TextAndCard>
      <TextAndCard texts={texts} images={images} index={1}></TextAndCard>
    </div>
  );
};

export default Scheduling;
