import React from "react";
import thumbnail from "../assets/software/thumbnai.png";

const Software = () => {
  return (
    <>
      <div className="w-auto font-FiraSans hidden md:block">
        <div className="w-auto flex mb-[8vh]">
          <div className="">
            <h2 className="text-left pl-[5vw] pt-[10vh] font-FireSans leading-tight text-[#45177D] font-[700] text-[6vw] md:text-[3.7vw]">
              Bus Service Planning
              <br />
              and Operation Software
            </h2>
          </div>
        </div>
        <div className="w-auto flex bg-[#894472]">
          <div className="p-[3vw] w-1/2 pb-[4vw] relative">
            <img
              className="absolute w-[15vw] right-[9vw] -top-[2vw]"
              src="/assets/software/circle1.png"
              alt=""
            />
            <img className="h-[35vh]" src="/assets/software/card1.png" alt="" />
          </div>
          <div className="w-1/2 text-white text-justify">
            <p className="mt-[10vh] px-[5vw] text-[1.1vw] font-thin">
              PUBBS Transit v.2.0 is developed as a web application based on the
              Python Flask web framework. The application allows for saving the
              user's data in the database, which can be fetched by the user
              whenever required. This enables seamless continuation of the data
              entry process. The user can keep track of the data entered through
              the sidebar panel, also helps in navigation. The application also
              allows the downloading of tabular data in CSV format so that the
              user can use their preferred spreadsheet software to store,
              process and generate reports using the data. The software also
              allows direct csv file input for data entry if required.
            </p>
          </div>
        </div>
        <div className="w-auto text-[#45177D] text-[1.3vw] my-[7vh]">
          <ul className="list-disc text-justify px-[5vw] space-y-[5vh]">
            <li>
              This software can be used to determine the optimal frequency for
              bus service. It allows for both manual bus frequency setting as
              well as Genetic algorithm-based optimal frequency determination.
              The user can adjust the final frequency to his liking, and the
              application can calculate the resulting cost instantly. The
              application allows visualization of the data such as routes, bus
              stops etc. and allows the user to build them from actual maps
              through easy click and drag procedure. The software enables the
              user to navigate to any location on a map using the search bar,
              and the user can plot stops along the corridor/area where bus
              service needs to be planned. This data is saved and made available
              to the user whenever desired. The application allows reuse of data
              linked to stops across routes. The user can draw a route using the
              saved stops, and the route will be shown visually. The application
              also allows for the editing an existing route.
            </li>
            <li>
              In the frequency setting module, the application can calculate the
              frequency and timetable of a bus route. The schedule and timetable
              for the buses as well as the crew can be generated using the bus
              scheduling module. Scheduling of buses can be done for a single
              route at a time or for two routes simultaneously following the
              sequential approach discussed earlier.
            </li>
            <li>
              The application can also be used by the bus operator to monitor
              the buses running on a route in real-time. It can show the
              scheduled arrival times of buses, their speed, their live location
              on a map, the total progress of the journey, etc. Along with this,
              it shows the delays and speed of buses in a graphical format using
              a space-time graph. This can be used to visualize bus bunching.
              Using the application, the operator will have access to a live
              feed from the buses, which will be sent from the driver console.
              The driver's console can be used by the driver of the bus to send
              real-time location information automatically. It can also be used
              to send an SOS alert to the operator in case of an emergency.
            </li>
            <li>
              The application can detect delays in bus arrivals and
              automatically adjust the schedule to prevent bus bunching. This is
              done by sending alerts to the driver to hold at control points.
              This improves the user's experience and minimizes costs at the
              same time.
            </li>
          </ul>
        </div>
      </div>

      <div className="w-auto font-FiraSans leading-tight md:hidden">
        <div className="w-full sm:w-auto h-[50vh] sm:mb-[15vh] flex">
          <div className="h-[25vh] sm:h-[40vh] relative w-[80%] bg-[#E32F6B14]">
            <div className="w-[280px] sm:w-[360px] absolute -right-[18vw] -bottom-[14vh] sm:-bottom-[9vh] shadow-lg rounded-xl">
              <img
                className="rounded-md"
                src="/assets/software/mobcard1.png"
                alt=""
              />
            </div>

            <h2 className="text-left pl-[5vw] pt-[5vh] font-FireSans leading-tight text-[#45177D] font-[700] sm:text-[2rem] text-[1.5rem]">
              Bus Service Planning
              <br />
              and Operation Software
            </h2>
          </div>
        </div>
        <div className="w-auto text-[#45177D] text-[3vw] mb-[5vh] space-y-[5vh] text-justify">
          <div className="p-[10vw] mr-[15vw] rounded-r-lg  bg-[#68507a3d]">
            PUBBS Transit v.2.0 is developed as a web application based on the
            Python Flask web framework. The application allows for saving the
            user's data in the database, which can be fetched by the user
            whenever required. This enables seamless continuation of the data
            entry process. The user can keep track of the data entered through
            the sidebar panel, also helps in navigation. The application also
            allows the downloading of tabular data in CSV format so that the
            user can use their preferred spreadsheet software to store, process
            and generate reports using the data. The software also allows direct
            csv file input for data entry if required.
          </div>

          <div className="p-[10vw] ml-[15vw] rounded-l-lg  bg-[#FDEFF4]">
            This software can be used to determine the optimal frequency for bus
            service. It allows for both manual bus frequency setting as well as
            Genetic algorithm-based optimal frequency determination. The user
            can adjust the final frequency to his liking, and the application
            can calculate the resulting cost instantly. The application allows
            visualization of the data such as routes, bus stops etc. and allows
            the user to build them from actual maps through easy click and drag
            procedure. The software enables the user to navigate to any location
            on a map using the search bar, and the user can plot stops along the
            corridor/area where bus service needs to be planned. This data is
            saved and made available to the user whenever desired. The
            application allows reuse of data linked to stops across routes. The
            user can draw a route using the saved stops, and the route will be
            shown visually. The application also allows for the editing an
            existing route.
          </div>

          <div className="p-[10vw] mr-[15vw] rounded-r-lg  bg-[#68507a3d]">
            In the frequency setting module, the application can calculate the
            frequency and timetable of a bus route. The schedule and timetable
            for the buses as well as the crew can be generated using the bus
            scheduling module. Scheduling of buses can be done for a single
            route at a time or for two routes simultaneously following the
            sequential approach discussed earlier.
          </div>

          <div className="p-[10vw] ml-[15vw] rounded-l-lg  bg-[#FDEFF4]">
            The application can also be used by the bus operator to monitor the
            buses running on a route in real-time. It can show the scheduled
            arrival times of buses, their speed, their live location on a map,
            the total progress of the journey, etc. Along with this, it shows
            the delays and speed of buses in a graphical format using a
            space-time graph. This can be used to visualize bus bunching. Using
            the application, the operator will have access to a live feed from
            the buses, which will be sent from the driver console. The driver's
            console can be used by the driver of the bus to send real-time
            location information automatically. It can also be used to send an
            SOS alert to the operator in case of an emergency.
          </div>

          <div className="p-[10vw] mr-[15vw] rounded-r-lg bg-[#68507a3d]">
            The application can detect delays in bus arrivals and automatically
            adjust the schedule to prevent bus bunching. This is done by sending
            alerts to the driver to hold at control points. This improves the
            user's experience and minimizes costs at the same time.
          </div>
        </div>
      </div>
    </>
  );
};

export default Software;
