import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import Image from "../assets/only bus bg-01.png";
import "./Form.css";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Form = () => {

  const form = useRef();
  const [fname, setfname] = useState("")
  const [lname, setlname] = useState("")
  const [number, setnumber] = useState("")
  const [email, setemail] = useState("")
  const [query, setquery] = useState("")
  const [cname, setcname] = useState("")

  const handleChange = (e) => {
    if (e.target.name === 'fname') {
      setfname(e.target.value)
    }
    else if (e.target.name === 'lname') {
      setlname(e.target.value)
    }
    else if (e.target.name === 'number') {
      setnumber(e.target.value)
    }
    else if (e.target.name === 'email') {
      setemail(e.target.value)
    }
    else if (e.target.name === 'query') {
      setquery(e.target.value)
    }
    else if (e.target.name === 'cname') {
      setcname(e.target.value)
    }
  }
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm('PUBSS_nt7puao', 'PUBBS_8dpy6fh', form.current, 'xiXjYscLJ59-qVQql')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
    toast.success("Response Submitted Successfully!", { autoClose: 4000 })
    setfname("")
    setlname("")
    setnumber("")
    setemail("")
    setquery("")
    setcname("")
  };

  return (
    <div>
      <div className="flex gap-x-8 w-full md:flex-row flex-col items-end justify-center">
        <form
          ref={form} onSubmit={sendEmail}
          className="flex-1 flex flex-col gap-x-0 gap-y-6 md:gap-10 w-full  xl:ml-36 lg:ml-12 md:ml-8  my-12 px-8 py-12 bg-[#FDEFF4] rounded-lg " style={{
            boxShadow: "4px 4px 4px 0px #00000040"
          }}
        >
          <div className="inside_form flex gap-x-6 w-full md:flex-row flex-col gap-y-6" >
            {/* fName */}
            <div className="relative z-0 w-full group h-[53px]">
              <input
                type="text"
                name="fname"
                id="fname"
                value={fname}
                onChange={handleChange}
                className="block px-4 h-full w-full text-lg text-accent border-2 bg-white shadow-lg border-[#FFFFFF] appearance-none focus:outline-none focus:ring-0 peer"
                placeholder="First Name"
                required
              />
            </div>

            {/* lname */}
            <div className="relative z-0 w-full group h-[53px]">
              <input
                type="text"
                name="lname"
                id="lname"
                value={lname}
                onChange={handleChange}
                className="block px-4 h-full w-full text-lg text-accent bg-white border-2 shadow-lg  border-[#FFFFFF] appearance-none focus:outline-none focus:ring-0 focus:border-[#FFFFFF] peer"
                placeholder="Last Name"
                required
              />
            </div>

          </div>

          <div className="inside_form flex gap-x-6 w-full md:flex-row flex-col gap-y-6" >
            {/* Email */}

            <div className="relative z-0 w-full group h-[53px]">
              <input
                type="email"
                name="email"
                id="email"
                value={email}
                onChange={handleChange}
                className="block px-4 h-full w-full text-lg text-accent bg-white border-2 shadow-lg border-[#FFFFFF] appearance-none focus:outline-none focus:ring-0 focus:border-[#FFFFFF] peer"
                placeholder="Business Email"
                required
              />
            </div>

            {/* number */}
            <div className="relative z-0 w-full group h-[53px]">
              <input
                type="number"
                name="number"
                id="number"
                value={number}
                onChange={handleChange}
                className="block px-4 h-full w-full text-lg text-accent bg-white border-2 shadow-lg border-[#FFFFFF] appearance-none focus:outline-none focus:ring-0 focus:border-[#FFFFFF] peer"
                placeholder="Phone Number"
                required
              />
            </div>

          </div>

          <div className="inside_form flex gap-x-6 w-full md:flex-row flex-col gap-y-6" >

            <div className="relative z-0 w-full group h-[53px]">
              <input
                type="text"
                name="cname"
                id="cname"
                value={cname}
                onChange={handleChange}
                className="block px-4 h-full w-full text-lg text-accent bg-white border-2 shadow-lg  border-[#FFFFFF] appearance-none focus:outline-none focus:ring-0 focus:border-[#FFFFFF] peer"
                placeholder="Company Name"
                required
              />
              <div className="relative z-0 w-full group h-[53px] hidden md:inline-block" id="dummy-box"></div>
            </div>

            <div className="inside_form flex gap-x-6 w-full md:flex-row flex-col gap-y-6" >
              <div className="relative z-0 w-full group h-[53px]">
                <textarea
                  type="text"
                  name="query"
                  id="query"
                  value={query}
                  onChange={handleChange}
                  className="block px-4 h-full w-full text-lg text-accent bg-white border-2 shadow-lg  border-[#FFFFFF] appearance-none focus:outline-none focus:ring-0 focus:border-[#FFFFFF] peer"
                  placeholder="State Your Query"
                  required
                />
              </div>
            </div>

          </div>  

            <button type='submit' value='Send' className="btn rounded-full text-white bg-[#8C025D] border-2 max-w-[140px] px-6 py-2 flex items-center justify-center overflow-hidden group">
              Submit
            </button>
        </form>

        <div className="md:w-[29%] lg:w-[46%] w-full flex items-end  justify-end ">
          <img className="lg:max-w-[125%] md:max-w-[165%] max-w-full" src={Image} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Form;
