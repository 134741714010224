import React from "react";
import TextAndCard from "../components/universal/TextAndCard";
import VideoCard from "../components/video/VideoCard";

const texts = [
  [
    "Current route conditions, ETA at bus stops, Delay and other emergency information",
    "Holding time of buses at specific bus stops",
    "Secondary AVL SYTEM",
    "Secondary CCTV system",
    "Panic button to transmit emergency information to the control room",
  ],
];
const images = [
  {
    card: "/assets/driver/card1.png",
    circle: "/assets/driver/circle1.png",
  },
];
const Driver = () => {
  return (
    <div className="w-auto font-FiraSans leading-tight">
      <div className="w-full sm:w-auto sm:h-[80vh] h-[60vh] flex">
        <div className="h-[40vh] md:h-[60vh] relative w-[80%] bg-[#E32F6B14]">
          <div className="w-[270px] sm:w-[500px] lg:w-[600px] absolute -right-[18vw] -bottom-[6vh] sm:-right-[17vw] lg:-right-[7vw] sm:-bottom-[22vh] md:-bottom-[15vh] shadow-lg rounded-xl">
            <VideoCard link="https://www.youtube.com/embed/RSbIEsfNwjE?rel=0"></VideoCard>
          </div>

          <h2 className="text-left pl-[5vw] pt-[10vh] font-FireSans leading-tight text-[#45177D] font-[700] text-[6.7vw] sm:text-[5.7vw] lg:text-[3.7vw]">
            Driver Console
          </h2>
        </div>
      </div>

      <TextAndCard texts={texts} images={images} index={0}></TextAndCard>

      {/* <div className="my-16 sm:my-20">
        <div className="w-full my-6 2xl:px-28 lg:px-20 sm:px-12 px-8 grid grid-cols-1 sm:grid-cols-2 sm:gap-x-4 gap-y-20 place-items-center">
          <div className="flex flex-col gap-y-2">
            <img src={card} alt="" className="" />

            <h3 className="text-[#45177D] font-light text-base text-center">
              Secondary CCTV system
            </h3>
          </div>

          <div className="flex flex-col gap-y-2">
            <img src={card} alt="" className="" />

            <h3 className="text-[#45177D] font-light text-base text-center">
              Secondary AVL system
            </h3>
          </div>

          <div className="flex flex-col gap-y-2">
            <img src={card} alt="" className="" />

            <h3 className="text-[#45177D] font-light text-base text-center">
              Current route conditions, ETA at bus stops, Delay and other
              emergency information
            </h3>
          </div>

          <div className="flex flex-col gap-y-2">
            <img src={card} alt="" className="" />

            <h3 className="text-[#45177D] font-light text-base text-center">
              Panic button to transmit emergency information to the control room
            </h3>
          </div>

          <div className="flex flex-col gap-y-2">
            <img src={card} alt="" className="" />

            <h3 className="text-[#45177D] font-light text-base text-center">
              Holding time of buses at specific bus stops
            </h3>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Driver;
