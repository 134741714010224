import React from 'react'
import arrow from '../assets/keyboard_double_arrow_left_FILL0_wght400_GRAD0_opsz48 6.png'

const Publications = () => {
    return (
        <div className='mb-16'>

            <div className='flex flex-col gap-y-6'>

                <h1 className='text-[#8C025D] font-semibold text-4xl mt-8 xl:mx-24 lg:mx-16 sm:mx-10 mx-6'>Publications</h1>

                <div className='flex items-center justify-center overflow-hidden'>

                    <div className='text-lg font-light flex flex-col gap-y-8 w-[80%] text-[#45177D]'>

                        <p className='flex gap-x-4 items-start justify-start'>
                            <img src={arrow} alt="" className='w-[20px] h-[20px]' />
                            <div>
                            Nayak S., Pandit D., A joint and simultaneous prediction framework of weekday and weekend daily-activity travel pattern using conditional dependency networks, Travel Behaviour and Society, Volume 32, 2023, 100595, <a href='https://doi.org/10.1016/j.tbs.2023.100595' className='text-blue-500 cursor-pointer' target='_blank' rel='noreferrer'>https://doi.org/10.1016/j.tbs.2023.100595</a> 
                            </div>
                            </p>


                        <p className='flex gap-x-4 items-start justify-start'>
                            <img src={arrow} alt="" className='w-[20px] h-[20px]' />
                            <div>
                            Nayak S., Pandit D., Activity-travel diary survey in an emerging country: constraints, design strategy and way forward post-pandemic. (Under review)
                            </div>
                            </p>

                        <p className='flex gap-x-4 items-start justify-start'>
                            <img src={arrow} alt="" className='w-[20px] h-[20px]' />
                            <div>
                            Nayak S., Pandit D., A novel hybrid approach for identification of representative daily-activity travel patterns using natural language processing considering semantic matching. (Under review)
                            </div>
                            </p>

                        <p className='flex gap-x-4 items-start justify-start'>
                            <img src={arrow} alt="" className='w-[20px] h-[20px]' />
                            <div>
                            Gajjela K., Biswas S., Goswami A.K., Pandit D., Analyzing Impacts of Strategic Measures on Enhancing Urban Travel- A Case of Bidhannagar, Kolkata, Proceedings of Urban Affairs Association (UAA) Conference, April 2023, USA. (Full paper)
                            </div>
                            </p>

                        <p className='flex gap-x-4 items-start justify-start'>
                            <img src={arrow} alt="" className='w-[20px] h-[20px]' />
                            <div>
                            Nayak S., Pandit D., A critical review of activity participation decision: a key component of activity-based travel demand models, International Journal of Urban Sciences. Taylor and Francis., 2022 , <a href="https://doi.org/10.1080/12265934.2022.2154249" className='text-blue-500 cursor-pointer' target='_blank' rel='noreferrer'>(https://doi.org/10.1080/12265934.2022.2154249 )</a>
                            </div>
                            </p>

                        <p className='flex gap-x-4 items-start justify-start'>
                            <img src={arrow} alt="" className='w-[20px] h-[20px]' />
                            <div>
                            Pandit, D., Sharma, D. (2022). Expected service dimensions and service levels for paratransit considering future mobility needs in emerging countries. Transportation Research Part A: Policy and Practice, Elsevier
                            <a href="https://doi.org/10.1016/j.tra.2022.05.021" className='text-blue-500 cursor-pointer' target='_blank' rel='noreferrer'>(https://doi.org/10.1016/j.tra.2022.05.021)</a>
                            </div>
                            </p>

                        <p className='flex gap-x-4 items-start justify-start'>
                            <img src={arrow} alt="" className='w-[20px] h-[20px]' />
                            <div>
                            Geethanjali J. and Pandit D., A protocol for designing a dynamic bus dispatch and control system along an urban corridor in the Indian context, Proceedings of the 4th National Conference on Traffic Technologies, 3-4 March 2022, Bhopal India.(Full paper)
                            </div>
                            </p>

                        <p className='flex gap-x-4 items-start justify-start'>
                            <img src={arrow} alt="" className='w-[20px] h-[20px]' />
                            <div>
                            Nayak S., Pandit D., Concerns for sustainable mobility during the post-pandemic situation in a developing country, India, Proceedings of the ARCS 6.0-2022 Conference at XIM university, Bhubaneshwar India, 2022. (Extended abstract)
                            </div>
                            </p>

                        <p className='flex gap-x-4 items-start justify-start'>
                            <img src={arrow} alt="" className='w-[20px] h-[20px]' />
                            <div>
                            Jaiswal, A., Pandit, D. (2023). A Full–Day Intercity Bus Frequency Setting Model Considering Dedicated Fleet Size and User Perception Using Genetic Algorithm. In: Devi, L., Das, A., Sahu, P.K., Basu, D. (eds) Proceedings of the Sixth International Conference of Transportation Research Group of India. CTRG 2021. Lecture Notes in Civil Engineering, vol 271. Springer, Singapore. <a href="https://doi.org/10.1007/978-981-19-3505-3_21" className='text-blue-500 cursor-pointer' target='_blank' rel='noreferrer'> https://doi.org/10.1007/978-981-19-3505-3_21</a>
                            </div>
                            </p>

                        <p className='flex gap-x-4 items-start justify-start'>
                            <img src={arrow} alt="" className='w-[20px] h-[20px]' />
                            <div>
                            Pandit, D., Sharma, D. (2023). Determinants of Users’ Perception of Fixed Route Paratransit Service Quality. In: Devi, L., Das, A., Sahu, P.K., Basu, D. (eds) Proceedings of the Sixth International Conference of Transportation Research Group of India. CTRG 2021. Lecture Notes in Civil Engineering, vol 271. Springer, Singapore. <a href="https://doi.org/10.1007/978-981-19-3505-3_13" className='text-blue-500 cursor-pointer' target='_blank' rel='noreferrer'> https://doi.org/10.1007/978-981-19-3505-3_13</a>
                            </div>
                            </p>

                        <p className='flex gap-x-4 items-start justify-start'>
                            <img src={arrow} alt="" className='w-[20px] h-[20px]' />
                            <div>
                            Sharma D., Pandit D., Determining the level of service measures to evaluate service quality of fixed-route shared motorized para-transit services, Transport Policy. Elsevier, 100, pp.176-186, 2021, DOI:10.1016/j.tranpol.2020.11.002
                            </div>
                            </p>

                        <p className='flex gap-x-4 items-start justify-start'>
                            <img src={arrow} alt="" className='w-[20px] h-[20px]' />
                            <div>
                            Bose T., Pandit D., Heterogeneity in perception of service quality attributes of bus transit across various user categories- A case of Kolkata, Transportation Research Procedia Journal. Elsevier Volume 48, Year 2020, Pages 2784-2799, DOI:10.1016/j.trpro.2020.08.239
                            </div>
                            </p>

                        <p className='flex gap-x-4 items-start justify-start'>
                            <img src={arrow} alt="" className='w-[20px] h-[20px]' />
                            <div>
                            Sharma D., Pandit D. et.al., Determination of service quality attributes based on user perception for paratransit services in developing country like India, Transportation Research Procedia Journal. Elsevier, Volume 48, Year 2020, Pages 3577-3594. DOI:10.1016/j.trpro.2020.08.093
                            </div>
                            </p>

                        <p className='flex gap-x-4 items-start justify-start'>
                            <img src={arrow} alt="" className='w-[20px] h-[20px]' />
                            <div>
                            Bhui S., Pandit D., Investigating factors responsible for reduction in bus ridership due to fixed route paratransit modes, International Conference for Future Cities, Indian Institute of Technology, Roorkee, India, December 2019. Link: <a href="https://www.researchgate.net/publication/340778965_Investigating_factors_responsible_for_reduction_in_bus_ridership_due_to_fixed_route_paratransit_modes" className='text-blue-500 cursor-pointer' target='_blank' rel='noreferrer'> https://www.researchgate.net/publication/340778965_Investigating_factors_responsible_for_reduction_in_bus_ridership_due_to_fixed_route_paratransit_modes </a>
                            </div>
                            </p>

                        <p className='flex gap-x-4 items-start justify-start'>
                            <img src={arrow} alt="" className='w-[20px] h-[20px]' />
                            <div>
                            Nayak S., Pandit D., Appraisal of Different Artificial Intelligence Techniques for Travel Demand Analysis, Proceedings of the 12th Urban Mobility India (UMI) Conference and Expo, 2019. Link: <a href="http://www.urbanmobilityindia.in/pdf/Proceedings_of_UMI2019.pdf" className='text-blue-500 cursor-pointer' target='_blank' rel='noreferrer'> http://www.urbanmobilityindia.in/pdf/Proceedings_of_UMI2019.pdf</a>
                            </div>
                            </p>

                        <p className='flex gap-x-4 items-start justify-start'>
                            <img src={arrow} alt="" className='w-[20px] h-[20px]' />
                            <div>
                            Nayak, S., Pandit, D. (2022). Activity-Based Model: Requisite for a New Travel Demand Forecasting Approach for India. In: Singh, D., Vanajakshi, L., Verma, A., Das, A. (eds) Proceedings of the Fifth International Conference of Transportation Research Group of India . Lecture Notes in Civil Engineering, vol 218. Springer, Singapore. <a href="https://doi.org/10.1007/978-981-16-9921-4_7" className='text-blue-500 cursor-pointer' target='_blank' rel='noreferrer'> https://doi.org/10.1007/978-981-16-9921-4_7</a>
                            </div>
                            </p>

                        <p className='flex gap-x-4 items-start justify-start'>
                            <img src={arrow} alt="" className='w-[20px] h-[20px]' />
                            <div>
                            Pandit D., Handbook on Feasible Service Delivery Level Ranges for Bus Transit: Part 1: User perceptive, Submitted in partial fulfillment for the project titled "Service Level Optimization between Public Bus and Para-transit Services along a Transport Corridor" (K-14011/18/2011-UT, dated 29-12-2015, Ministry of Housing and Urban Affairs, GoI 2019. Pandit D., Handbook on Feasible Service Delivery Level Ranges for Bus Transit: Part 2: Operator perceptive, Submitted in partial fulfillment for the project titled "Service Level Optimization between Public Bus and Para-transit Services along a Transport Corridor" (K-14011/18/2011-UT, dated 29-12-2015) Ministry of Housing and Urban Affairs, GoI 2019.
                            </div>
                            </p>

                        <p className='flex gap-x-4 items-start justify-start'>
                            <img src={arrow} alt="" className='w-[20px] h-[20px]' />
                            <div>
                            Pandit D., Handbook on Para-transit Service Design in Indian Context: Part 1: Operation and Regulation, Submitted in partial fulfillment for the project titled "Service Level Optimization between Public Bus and Para-transit Services along a Transport Corridor" (K-14011/18/2011-UT, dated 29-12-2015) Ministry of Housing and Urban Affairs, GoI 2019.
                            </div>
                            </p>

                        <p className='flex gap-x-4 items-start justify-start'>
                            <img src={arrow} alt="" className='w-[20px] h-[20px]' />
                            <div>
                            Pandit D., Handbook on Para-transit Service Design in Indian Context: Part 2: Integration, Submitted in partial fulfillment for the project titled "Service Level Optimization between Public Bus and Para-transit Services along a Transport Corridor" (K-14011/18/2011-UT, dated 29-12-2015) Ministry of Housing and Urban Affairs, GoI 2019.
                            </div>
                            </p>

                        <p className='flex gap-x-4 items-start justify-start'>
                            <img src={arrow} alt="" className='w-[20px] h-[20px]' />
                            <div>
                            Pandit D., Service Level Optimisation between Public Bus and Para-transit Services along a Transport Corridor: Summary Report, Submitted in partial fulfillment for the project titled "Service Level Optimization between Public Bus and Para-transit Services along a Transport Corridor" (K-14011/18/2011-UT, dated 29-12-2015) Ministry of Housing and Urban Affairs, GoI 2019.
                            </div>
                            </p>

                        <p className='flex gap-x-4 items-start justify-start'>
                            <img src={arrow} alt="" className='w-[20px] h-[20px]' />
                            <div>
                            Pandit D., PUBBS transit Software Manual Submitted in partial fulfillment for the project titled "Service Level Optimization between Public Bus and Para-transit Services along a Transport Corridor" (K-14011/18/2011-UT, dated 29-12-2015) Ministry of Housing and Urban Affairs, 2019. Bose T., Pandit D., A Methodology to Prioritise Service Quality Attributes for Bus Transit- A Case Study of Visakhapatnam, Urban Transport Research Journal, ISSN 2395-2492, 2018.
                            </div>
                            </p>

                        <p className='flex gap-x-4 items-start justify-start'>
                            <img src={arrow} alt="" className='w-[20px] h-[20px]' />
                            <div>
                            Sharma D., Pandit D., Review of operational parameters of paratransit services: A case of Kolkata, Proceedings of the 11th Urban Mobility India (UMI) Conference and Expo, 2018.
                            </div>
                            </p>

                        <p className='flex gap-x-4 items-start justify-start'>
                            <img src={arrow} alt="" className='w-[20px] h-[20px]' />
                            <div>
                            Das S., Pandit D., Methodology to determine service delivery levels for public transportation, Transportation Planning and Technology, Taylor and Francis, 39 (2), pp. 195-217, 2016. DOI:10.1080/03081060.2015.1127541
                            </div>
                            </p>

                        <p className='flex gap-x-4 items-start justify-start'>
                            <img src={arrow} alt="" className='w-[20px] h-[20px]' />
                            <div>
                            Das S., Pandit D., Qualitative assessment of public facilities: the public bus, The TQM Journal. Emerald Insight, 28(2), pp. 275-294, 2016. DOI:10.1108/TQM-11-2014-0096
                            </div>
                            </p>

                        <p className='flex gap-x-4 items-start justify-start'>
                            <img src={arrow} alt="" className='w-[20px] h-[20px]' />
                            <div>
                            Das S., Pandit D., Determination of level-of-service scale values for quantitative bustransit service attributes based on user perception, Transportmetrica A: Transport Science. Taylor and Francis, 11(1) pp. 1-21, 2015. DOI:10.1080/23249935.2014.910563
                            </div>
                            </p>

                        <p className='flex gap-x-4 items-start justify-start'>
                            <img src={arrow} alt="" className='w-[20px] h-[20px]' />
                            <div>
                            Pandit D., Das S., A Framework for Determining Commuter Preference along a Proposed Bus Rapid Transit Corridor, Procedia-Social and Behavioral Sciences. Elsevier, 104, pp.894-903, 2013. <a href="https://doi.org/10.1016/j.sbspro.2013.11.184" className='text-blue-500 cursor-pointer' target='_blank' rel='noreferrer'> https://doi.org/10.1016/j.sbspro.2013.11.184</a>
                            </div>
                            </p>

                        <p className='flex gap-x-4 items-start justify-start'>
                            <img src={arrow} alt="" className='w-[20px] h-[20px]' />
                            <div>
                            Das S., Pandit D., Importance of user perception in evaluating level of service for bus transit for a developing country like India: a review, Transport Reviews. Taylor and Francis, 33(4), pp. 402-420, 2013. DOI:10.1080/01441647.2013.789571
                            </div>
                            </p>

                        <p className='flex gap-x-4 items-start justify-start'>
                            <img src={arrow} alt="" className='w-[20px] h-[20px]' />
                            <div>
                            Pandit D., Das S., A framework for bus transit reform & redesign: Case study Kolkata, Proceedings of the 13th International Conference on Computers in Urban Planning and Management, 2013. Link:
                            <a href="https://cupum2013.geo.uu.nl/download/usb/contents/pdf/shortpapers/113_Pandit.pdf" className='text-blue-500 cursor-pointer' target='_blank' rel='noreferrer'> https://cupum2013.geo.uu.nl/download/usb/contents/pdf/shortpapers/113_Pandit.pdf</a>
                            </div>
                            </p>

                        <p className='flex gap-x-4 items-start justify-start'>
                            <img src={arrow} alt="" className='w-[20px] h-[20px]' />
                            <div>
                            Das S., Pandit D., Methodology to determine Level of Service for Bus Transit in a developing country like India, Proceedings of the 13th International Conference on Computers in Urban Planning and Management, 2013. Link: <a href="https://cupum2013.geo.uu.nl/download/usb/contents/pdf/shortpapers/112_Das.pdf" className='text-blue-500 cursor-pointer' target='_blank' rel='noreferrer'> https://cupum2013.geo.uu.nl/download/usb/contents/pdf/shortpapers/112_Das.pdf</a>
                            </div>
                            </p>

                        <p className='flex gap-x-4 items-start justify-start'>
                            <img src={arrow} alt="" className='w-[20px] h-[20px]' />
                            <div>
                            Pandit D., Project Report: Development of a Methodology for bus transit system reform and redesign: Case study Kolkata Submitted to ISIRD SRIC, IIT Kharagpur, August 2013
                            </div>
                            </p>

                        <p className='flex gap-x-4 items-start justify-start'>
                            <img src={arrow} alt="" className='w-[20px] h-[20px]' />
                            <div>
                            Das S., Pandit D., Methodology to identify the gaps in the level of service provided for urban bus transit: Case study Kolkata, Spandrel,4(spring), pp. 59-71, 2012.
                            </div>
                            </p>

                        <p className='flex gap-x-4 items-start justify-start'>
                            <img src={arrow} alt="" className='w-[20px] h-[20px]' />
                            <div>
                            Maparu T. S., Pandit D., Bus Route Merging and Frequency Setting Methodology for Bus Rapid Transit Trunk Corridor: A Case Study of Kolkata, Space: The SPA Journal of Planning and Architecture, 16(2), pp. 16-37, 2012.
                            </div>
                            </p>

                        <p className='flex gap-x-4 items-start justify-start'>
                            <img src={arrow} alt="" className='w-[20px] h-[20px]' />
                            <div>
                            Pandit D., Maparu T., A methodology for bus transit system reform & redesign: Case study Kolkata, Proceedings of the 12th International Computers in Urban Planning and Management Conference, 2011.
                            </div>
                            </p>

                        <p className='flex gap-x-4 items-start justify-start'>
                            <img src={arrow} alt="" className='w-[20px] h-[20px]' />
                            <div>
                            Das S., Pandit D., Level of Service parameters for bus transit in the Indian Context, Proceedings of the 1st Conference of Transportation Research Group of India, 2011.
                            </div>
                            </p>

                        <p className='flex gap-x-4 items-start justify-start'>
                            <img src={arrow} alt="" className='w-[20px] h-[20px]' />
                            <div>
                            Maparu T. S., Pandit D., A Methodology for Selection of Bus Rapid Transit Corridors: A Case Study of Kolkata, Institute of Town Planners, India Journal, 7(4), pp. 21-36, 2010. Link: <a href="http://www.itpi.org.in/uploads/journalfiles/oct7_10.pdf" className='text-blue-500 cursor-pointer' target='_blank' rel='noreferrer'> http://www.itpi.org.in/uploads/journalfiles/oct7_10.pdf</a>
                            </div>
                            </p>


                    </div>

                </div>
            </div>
        </div>
    )
}

export default Publications