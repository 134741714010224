import "./App.css";
import Home from "./pages/Home";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import { Route, Routes } from "react-router-dom";
import Network from "./pages/Network";
import Software from "./pages/Software";
import Frequency from "./pages/Frequency";
import Crew from "./pages/Crew";
import Driver from "./pages/Driver";
import Scheduling from "./pages/Scheduling";
import Control from "./pages/Control";
import Resources from "./pages/Resources";
import ResearchProject from "./pages/ResearchProject";
import Publications from "./pages/Publications";
import Videos from "./pages/Videos";
import Form from "./pages/Form";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CaseStudy from "./pages/CaseStudy";
import Electric from "./pages/Electric";

function App() {
  return (
    <>
      <ToastContainer
        position="top-left"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
        theme="dark"
      />
      <Header></Header>
      <Routes>
        <Route path="/" element={<Home></Home>}></Route>
        <Route path="/network" element={<Network></Network>}></Route>
        <Route path="/software" element={<Software />}></Route>
        <Route path="/frequency" element={<Frequency />}></Route>
        <Route path="/crew" element={<Crew></Crew>}></Route>
        <Route path="/driver" element={<Driver></Driver>}></Route>
        <Route path="/scheduling" element={<Scheduling />}></Route>
        <Route path="/control" element={<Control />}></Route>
        <Route path="/resources-software" element={<Resources />}></Route>
        <Route path="/research" element={<ResearchProject />}></Route>
        <Route
          path="/publications"
          element={<Publications></Publications>}
        ></Route>
        <Route path="/videos" element={<Videos />}></Route>
        <Route path="/form" element={<Form />}></Route>
        <Route path="/caseStudy" element={<CaseStudy />}></Route>
        <Route path="/electric" element={<Electric />}></Route>
      </Routes>
      <Footer></Footer>
    </>
  );
}

export default App;
