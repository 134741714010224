import React from "react";
//import slide1 from "../../assets/extrainfo/slide 1(mob).png";
import slide1 from "../../assets/extrainfo/majorBenefits1.jpg";
import slide2 from "../../assets/extrainfo/majorBenefits2.jpg";
import slide3 from "../../assets/extrainfo/majorBenefits3.jpg";
import slide4 from "../../assets/extrainfo/majorBenefits4.jpg";
import slide5 from "../../assets/extrainfo/majorBenefits5.jpg";
import slide6 from "../../assets/extrainfo/majorBenefits6.jpg";

const data = [
  {
    image: slide1,
    text: "Real time prediction of travel demand and travel time along a bus route at different time periods for each day.",
  },
  {
    image: slide2,
    text: "Real time bus dispatch and control system for operators at terminals",
  },
  {
    image: slide3,
    text: "Real time information on bus location, passenger load inside vehicles, level of crowding for the operators",
  },
  {
    image: slide4,
    text: "Automatic generation of bus and crew schedule",
  },
  {
    image: slide5,
    text: "Real time information on current route conditions, expected time of arrival at bus stops, delay and other emergency information for bus drivers through an on-board driver console",
  },
  {
    image: slide6,
    text: "On-board surveillance system with panic button to transmit emergency information to the control room",
  },
];

const InfoCard = (props) => {
  return (
    <div className="max-h-[60vh] sm:h-full w-full sm:w-[30vw] lg:w-[24.73vw] sm:shadow-gray-500 sm:shadow-lg rounded-lg flex items-center flex-col justify-center gap-y-6 sm:gap-y-0 sm:justify-between aspect-[475/555]">
      <img
        src={data[props.index].image}
        alt=""
        className="w-[80%] inline-block sm:hidden"
      />
      <img
        src={data[props.index].image}
        alt=""
        className=" h-[25vh] bg-clip-content hidden sm:inline-block"
      />

      <div className="max-w-[30rem] sm:w-auto ml-[3vw] sm:mx-[3vw] text-justify font-FiraSans text-[#45177D] leading-tight">
        <h3 className="mb-[2vh] sm:my-[2vh] font-semibold">Major Benefits:</h3>

        <p className="font-[200] text-[15px] sm:text-sm lg:text-base">
          {data[props.index].text}
        </p>
      </div>

      <div className="flex sm:space-x-[0.3vw] space-x-2 mt-0 sm:mt-[2vh] lg:mt-0 sm:mb-[2vh] mb-0">
        <div
          className={
            "sm:h-[1vw] h-[2vw] lg:h-[0.5vw] border-[#45177D] border-[0.05vw] rounded-full aspect-square " +
            (props.index === 0 ? "bg-[#45177D]" : "")
          }
        ></div>

        <div
          className={
            "sm:h-[1vw] h-[2vw] lg:h-[0.5vw] border-[#45177D] border-[0.05vw] rounded-full aspect-square " +
            (props.index === 1 ? "bg-[#45177D]" : "")
          }
        ></div>
        <div
          className={
            "sm:h-[1vw] h-[2vw] lg:h-[0.5vw] border-[#45177D] border-[0.05vw] rounded-full aspect-square " +
            (props.index === 2 ? "bg-[#45177D]" : "")
          }
        ></div>
        <div
          className={
            "sm:h-[1vw] h-[2vw] lg:h-[0.5vw] border-[#45177D] border-[0.05vw] rounded-full aspect-square " +
            (props.index === 3 ? "bg-[#45177D]" : "")
          }
        ></div>
        <div
          className={
            "sm:h-[1vw] h-[2vw] lg:h-[0.5vw] border-[#45177D] border-[0.05vw] rounded-full aspect-square " +
            (props.index === 4 ? "bg-[#45177D]" : "")
          }
        ></div>
        <div
          className={
            "sm:h-[1vw] h-[2vw] lg:h-[0.5vw] border-[#45177D] border-[0.05vw] rounded-full aspect-square " +
            (props.index === 5 ? "bg-[#45177D]" : "")
          }
        ></div>
      </div>
    </div>
  );
};

export default InfoCard;
